import { FACTOR_ICON_SIZE} from '../../Constants';

// function to add the selected icon to the factor
export const addSelectedIcon = (selection) => {

  selection.select("g.factor-selected").remove();

  selection
  .append('g')
  .attr('class', 'factor-selected')
  .attr("transform", d => `translate(${-FACTOR_ICON_SIZE},${-FACTOR_ICON_SIZE}) scale(0.75)`)
  .append('svg')
  .attr('color','green')
  .append('use')
  .attr('xlink:href', d => `#FactorSelected`);
};

export const removeSelectedFactor = selection => {
  selection.select('.factor-selected').remove();
}