import { Component } from "react";
import Badge from "react-bootstrap/Badge";

class Rawdata extends Component {


  render() {

    const links = [
      ["East Asia and Pacific", "CRISP_impact_chains_EAP.zip"],
      ["Latin America and Caribbean", "CRISP_impact_chains_LAC.zip"],
      ["Middle East and North Africa", "CRISP_impact_chains_MENA.zip"],
      ["South Asia", "CRISP_impact_chains_SA.zip"],
      ["Sub-Saharan Africa", "CRISP_impact_chains_SSA.zip"]
    ];

    return <div className="wrapper-data">
      <h3>CRISP data</h3>
      <div className="content">
          <h5>Raw impact chain data</h5>
          <div className="data-profiles">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
              <Badge pill bg="light" text="dark">Scientists</Badge><Badge pill bg="light" text="dark">Practitioners</Badge>
          </div>
          <p>
            Impact chains provided in the CRISP tool have been acquired from current literature sources through the help of domain experts, who initially filled in an Excel form for each impact chain.
          </p>          
          <p>
            The original Excel files are available for download under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">CC BY-NC-SA 4.0 license</a> at the following links, for use by both technical and non-technical users. They Excel files are organized by macro-region (one ZIP file per region) and provided in their original unprocessed format (including notes from domain experts and any inconsistencies).
          </p>
          <p>
            After the Excel forms have been filled in initially, the raw data was processed into the digital and interactive CRISP tool:  The raw data was extracted, validated, and integrated into the tool, resolving inconsistencies and incorporating additional data (e.g., geographical) to ultimately obtain the information accessible via CRISP.
          </p>
          <ul>
          {
            links.map(
              (link, i) => <li key={i}>                
                <a href={process.env.REACT_APP_URL_DATA + "/" + link[1]}
                  target="_blank">
                  {link[0]}
                </a>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2A66FF" className="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                  </svg>
              </li>
            )
          }
          </ul>
      </div>      
      <div className="content">
          <h5>CRISP knowledge graph</h5>
          <div className="data-profiles">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
              <Badge pill bg="light" text="dark">Technical data managers</Badge>
          </div>
          <p>
            The CRISP web tool leverages a <a href="https://en.wikipedia.org/wiki/Knowledge_graph" target="_blank">knowledge graph</a>, i.e., a structured way to represent (and query and reason over) information about entities, their properties and their connections. This graph integrates all acquired data (impact chains, farming systems, bibliographic resources, geographical entities, etc.). The underlying structure is based on a semantically rich ontological model. 
          </p>          
          <p>
            Technical users can download the knowledge graph (in <a href="https://www.w3.org/TR/n-triples/" target="_blank">RDF</a> format with ZIP compression, <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">CC BY-NC-SA 4.0 license</a>) at the link below. The downloaded file can be used with RDF triplestores (like <a href="https://www.ontotext.com/products/graphdb/download/" target="_blank">GraphDB</a>, <a href="https://vos.openlinksw.com/owiki/wiki/VOS" target="_blank">Virtuoso</a>) or in RDF/OWL editors (e.g., <a href="https://protege.stanford.edu/" target="_blank">Protégé</a>) to explore the data. Alternatively, programmers can use RDF libraries (such as <a href="https://rdflib.dev/" target="_blank">RDFlib</a>, <a href="https://rdf4j.org/" target="_blank">RDF4J</a>, <a href="https://jena.apache.org/" target="_blank">Jena</a>, or <a href="https://librdf.org/" target="_blank">Redland</a>) to process the information.
          </p>
          <ul>
            <li>
                <a href={process.env.REACT_APP_URL_DATA + "/kg/" + "CRISP_knowledge_graph.zip"}
                  target="_blank">
                  CRISP knowledge graph
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2A66FF" className="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                  </svg>
            </li>
          </ul>
      </div>         
    </div>
  }
}
export default Rawdata;