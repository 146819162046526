import { FACTOR_NAME_SPLIT_STRING } from '../Constants';
import * as _ from 'lodash';



export const getICModelLinksFromNode = (nodeLabel, ICModelLinks) => {
  // get relationships within the factor links
  return _(ICModelLinks)
    .pickBy( (value, key) => key.split(FACTOR_NAME_SPLIT_STRING).includes(nodeLabel) )
    .value();
}



export const getNodesAndLinks = (
  ICModelLinks,
  factorsInContext, 
  rejectedFactorTypes = [], 
  rejectedFactorSubTypes = [],
  selectedFactors
) => {  
  /*
    ICModelLinks is an object, each <key,value> is like this one:
    - key is the pair of factor names linked
    - value is the relationships between them
    {
      "Competition for resources@@@Increase in drought events":       
        [
          {
            "factorName":"Competition for resources",
            "factorTypeName":"Impact",
            "factorSubtypeName":"HumanCapitalAdaptation",
            "linkType":"IsCausedBy",
            "relFactorName":"Increase in drought events",
            "relFactorTypeName":"Hazard"
            "relFactorSubtypeName":"HumanCapitalAdaptation"
          },
          {
            "factorName":"Increase in drought events",
            "factorTypeName":"Hazard",
            "factorSubtypeName":"HumanCapitalAdaptation",
            "linkType":"LeadsTo",
            "relFactorName":"Competition for resources",
            "relFactorTypeName":"Impact",
            "relFactorSubtypeName":"HumanCapitalAdaptation"
          }
      ]
    }
  */
 
  // get list of links
  const links = _.keys(ICModelLinks)
  .map(key => _.set(
    _.zipObject(
      ['source', 'target'], key.split(FACTOR_NAME_SPLIT_STRING)
    ),
    'type', _.cloneDeep(ICModelLinks[key])
  )
  );

  // get list of nodes. ICModelLinks does not contain all the nodes, since there are
  // nodes without link. Take the nodes from 'factorsInContext', which contains 
  // the full list
  /*let nodes = _(ICModelLinks)
    .values()
    .flatten()
    .uniqBy('factorName')
    .map(o => _.zipObject(
      ['label', 'type', 'subtype', 'data'], 
      [o.factorName, o.factorTypeName, o.factorSubtypeName, o]
      )
    )
    .value();*/
  let nodes = _(factorsInContext)
    .values()
    .map(o => _.zipObject(
      ['label', 'type', 'subtype', 'data'],
      [o.factorName, o.factorTypeName, o.factorSubtypeName, o]
    ))
    .value();

  /*
    return an object like
    {
      links: [
        { source: 'xxx', target: 'yyy', type: <see structure we receive in the function> },
        ...
      ],
      nodes: [
        { label: 'xxx', type: 'xxx', subtype: 'xxx', data:o},
        ...
      ]
    }
  */
        
  if(!_.isEmpty(selectedFactors)) {
    let selectedFactorNames = _.map(selectedFactors, 'label');
    return { 
      nodes: _.filter(
        nodes,
        node => selectedFactorNames.includes(node.label)
      ), 
      // filtering in links is done via the property 'type', since the properties
      // 'source' and 'target' are modified later by the d3-force
      links: _.filter(
        links, 
        link => _.intersection([link.source, link.target], selectedFactorNames).length === [link.source, link.target].length
      )
    };
  }

  return { 
    nodes: _.filter(
      nodes,
      node => !rejectedFactorTypes.includes(node.type) && !rejectedFactorSubTypes.includes(node.subtype)
    ), 
    // filtering in links is done via the property 'type', since the properties
    // 'source' and 'target' are modified later by the d3-force
    links: _.filter(
      links, 
      link => _.isEmpty(
        _.intersection(_.map(link.type, 'factorTypeName'), rejectedFactorTypes)         
      ) && _.isEmpty(
        _.intersection(_.map(link.type, 'factorSubtypeName'), rejectedFactorSubTypes)
      )
    )
  };
}