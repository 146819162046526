import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function HeaderBar() {
  return (
      <Navbar expand="lg" bg="primary" variant="dark" className='crisp-navbar'>
        <Container>
          <Navbar.Brand href="#home">
            CRISP<br/> <span className='crisp-mobile'>Climate Risk Planning & Managing Tool<br/>for Development Programmes in Agri-food Systems</span>

            </Navbar.Brand>          
        </Container>
      </Navbar>
  );
}

export default HeaderBar;