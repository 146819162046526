import * as _ from 'lodash';
import SVG_FACTOR_ADAPTATION from './factor-icons/svg/adaptation.svg'; //adpatation-wrench-adjustable.svg';
import SVG_FACTOR_EXPOSURE from './factor-icons/svg/exposure.svg'; //exposure-water.svg';
import SVG_FACTOR_HAZARD from './factor-icons/svg/hazard.svg'; //hazard-exclamation-circle-fill.svg';
import SVG_FACTOR_IMPACT from './factor-icons/svg/impact.svg'; //impact-clipboard2-pulse-fill.svg';
import SVG_FACTOR_RISK from './factor-icons/svg/risk.svg'; //risk-bell-fill.svg';
import SVG_FACTOR_VULNERABILITY from './factor-icons/svg/vulnerability.svg'; //vulnerability-lightning-charge-fill.svg';
import SVG_VISIBLE_FACTOR from './factor-icons/eye.svg';
import SVG_NOT_VISIBLE_FACTOR from './factor-icons/eye-slash.svg';

import {
  Svg, 
  Path, 
  Rect
} from '@react-pdf/renderer';

//export const URL_IMAGES = 'https://www.xavigimenez.net/unibz/crisp-ui/images';
export const URL_IMAGES = './images';

export const OPACITY_OFF = 0.05;
export const OPACITY_ON = 1;

export const ALL = 1;
export const SELECTED = 0;

let COMMODITIES = 'commodities',
  FARMING_SYSTEM = 'farmingSystem',
  COUNTRIES = 'countries',
  REGIONS = 'regions', 
  IS_PART_OF = 'isPartOf';

export const LABELS = {
  [COMMODITIES]: 'Commodities',
  [FARMING_SYSTEM]: 'Farming System',
  [COUNTRIES]: 'Countries'
}
export const FARMING_SYSTEM_DIM = FARMING_SYSTEM;

export const FARMING_SYSTEM_KEY_CHARACTERISTICS = {
  "totalPopulation": "Total population (m)",
  "agriculturalPopulation": "Agricultural population (m)",
  "cattlePopulation": "Cattle population (m head)",
  "totalArea": "Total area (m ha)",
  "cultivatedArea": "Cultivated area (m ha)",
  "irrigatedArea": "Irrigated area (m ha)",
  "agroEcologicalZone": "Agro-ecological zone",
  "countries": "Countries within the agri system"
}

// properties that contain multivalued strings (FR;ES;CA;...)
export const MULTIVALUED_PROPS = [REGIONS, COUNTRIES, IS_PART_OF, COMMODITIES];

// proprties to use in the facet browser
export const DIMENSIONS = [COUNTRIES, FARMING_SYSTEM /*, COMMODITIES*/];

// check if a crossfilter dimension is an array.
export const DIMENSION_IS_ARRAY = (dimension) => MULTIVALUED_PROPS.includes(dimension);

// null values in properties are converted into [] or ''
export const SANITIZE_PROPERTY = (value, key) => {
  return !_.isNil(value)? value : DIMENSION_IS_ARRAY(key)? []:''
}

export const FACTOR_SPECIALIZATION_ADAPTATION ='Adaptation';
export const FACTOR_SPECIALIZATION_EXPOSURE = 'Exposure';
export const FACTOR_SPECIALIZATION_HAZARD = 'Hazard';
export const FACTOR_SPECIALIZATION_IMPACT = 'Impact';
export const FACTOR_SPECIALIZATION_RISK = 'Risk';
export const FACTOR_SPECIALIZATION_VULNERABILITY = 'Vulnerability';
export const FACTOR_VISIBLE= 'FactorVisible';
export const FACTOR_NOT_VISIBLE = 'FactorNotVisible';

let factorIconSize = 16
export const NODE_MENU_BOX = factorIconSize*4;
export const FACTOR_ICON_SIZE = factorIconSize;
export const FACTOR_ICONS = {  
  [FACTOR_SPECIALIZATION_HAZARD]: SVG_FACTOR_HAZARD,
  [FACTOR_SPECIALIZATION_IMPACT]: SVG_FACTOR_IMPACT,
  [FACTOR_SPECIALIZATION_VULNERABILITY]: SVG_FACTOR_VULNERABILITY,
  [FACTOR_SPECIALIZATION_ADAPTATION]: SVG_FACTOR_ADAPTATION,
  [FACTOR_SPECIALIZATION_EXPOSURE]: SVG_FACTOR_EXPOSURE,  
  [FACTOR_SPECIALIZATION_RISK]: SVG_FACTOR_RISK
};
export const FACTOR_TYPES_SORTED = [
  FACTOR_SPECIALIZATION_HAZARD,
  FACTOR_SPECIALIZATION_IMPACT,
  FACTOR_SPECIALIZATION_VULNERABILITY,
  FACTOR_SPECIALIZATION_ADAPTATION,
  FACTOR_SPECIALIZATION_EXPOSURE,
  FACTOR_SPECIALIZATION_RISK
];
export const FACTOR_SORT_ORDER = {  
  [FACTOR_SPECIALIZATION_HAZARD]: 100,
  [FACTOR_SPECIALIZATION_IMPACT]: 90,
  [FACTOR_SPECIALIZATION_VULNERABILITY]: 80,
  [FACTOR_SPECIALIZATION_ADAPTATION]: 70,
  [FACTOR_SPECIALIZATION_EXPOSURE]: 60,
  [FACTOR_SPECIALIZATION_RISK]: 50
};

export const FACTOR_VISIBILITY = {
  [FACTOR_VISIBLE]: SVG_VISIBLE_FACTOR,
  [FACTOR_NOT_VISIBLE]: SVG_NOT_VISIBLE_FACTOR
}

export const FACTOR_SPECIALIZATIONS = [
  FACTOR_SPECIALIZATION_HAZARD,
  FACTOR_SPECIALIZATION_EXPOSURE,
  FACTOR_SPECIALIZATION_IMPACT,
  FACTOR_SPECIALIZATION_VULNERABILITY,
  FACTOR_SPECIALIZATION_ADAPTATION, 
  FACTOR_SPECIALIZATION_RISK
];

// all possible factor types
// Not harcoded anymore, this is now retrieved from a sparql query
/*export const FACTOR_TYPES = {
  [FACTOR_SPECIALIZATION_ADAPTATION]: [
    'CombinedAdaptationList',
    'FinancialCapitalAdaptation',
    'HumanCapitalAdaptation',
    'NaturalCapitalAdaptation',
    'PhysicalCapitalAdaptation',
    'PoliticalCapitalAdaptation',
    'SocialCapitalAdaptation'
  ],  
  [FACTOR_SPECIALIZATION_EXPOSURE]: [],
  [FACTOR_SPECIALIZATION_HAZARD]: [
    'MaritimeRelatedHazard',
    'RainfallRelatedHazard',
    'SeasonRelatedHazard',
    'TemperatureRelatedHazard',
    'WindRelatedHazard'
  ],
  [FACTOR_SPECIALIZATION_IMPACT]: [
    'BiophysicalImpact',
    'SocioeconomicImpact'
  ],
  [FACTOR_SPECIALIZATION_RISK]: [],
  [FACTOR_SPECIALIZATION_VULNERABILITY]: [
    'Risk',
    'EconomicRelatedVulnerability',
    'HumanRelatedVulnerability',
    'InstitutionRelatedVulnerability',
    'LackOfCapacity',
    'Sensitivity-SusceptibilityToHarm'
  ]
};*/

export const FACTOR_NAME_SPLIT_STRING = '@@@';
export const FACTOR_ID_SPLIT_STRING = '@';

export const URL_ID_IMPACT_CHAIN = "icid";
export const URL_ID_FACTORS = "fids";
export const URL_ID_FACTOR_TYPES = "ftids";
export const URL_ID_FACTOR_SUBTYPES = "fstids";
export const SKIP_MODAL_MESSAGE = true;
export const SKIP_VIEW_NETWORK = true;
export const URL_ID_REGEX = /^[0-9a-zA-Z]{2}$/;
export const URL_CRISP_SESSION = "sidcrisp";

export const HIGHLIGHT_ORIGIN_LEGEND = 'legend';
export const HIGHLIGHT_ORIGIN_NETWORK = 'network';

export const VIEW_HOME = 1;
export const VIEW_BACKGROUND = 2;
export const VIEW_CONTEXT_SELECTION = 5;
export const VIEW_NETWORK = 10;
export const VIEW_TABLE = 20;
export const VIEW_OTHER_TOOLS = 30;
export const VIEW_USER_GUIDE = 40;
export const VIEW_VIDEOS = 50;
export const VIEW_USE_CASES = 60;
export const VIEW_RAW_DATA = 70;

export const MODAL_MESSAGE_IMPACT_CHAIN_SELECT = "The following impact chains are available based on your context definition, please select one below:";
export const MODAL_MESSAGE_GENERATING_IMAGE = "Generating image...";
export const MODAL_MESSAGE_GENERATING_REPORT = "Generating report...";
export const MODAL_MESSAGE_LOADING_DATA = "Loading data...";
export const MODAL_MESSAGE_LOADING_IMPACT_CHAIN = "Loading impact chains...";
export const MODAL_MESSAGE_LOADING_CRISP_SESSION = "Restoring existing CRISP session...";


const COLOR_CRISP_BLUE = '#2A66FF';
const COLOR_CRISP_GREY_PRIMARY = '#404649';

// styles for the PDF report
export const STYLE_SHEET_PDF = {
  page: { 
    backgroundColor: 'white',
    fontFamily: 'Helvetica',
    paddingTop: 60,
    paddingBottom: 60
  },
  header: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  headerText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'white',
    position: 'absolute',
    left: 40,
    top: 12
  },
  headerLogo: {    
    position: 'absolute',
    left: 5,
    top: 5,
    width:24,
    height:24
  },
  headerLogoBMZ: {    
    position: 'absolute',
    right: 140,
    top: 3,
    height:30
  },
  headerLogoGIZ: {    
    position: 'absolute',
    right: 10,
    top: 3,
    height:30
  },
  subheader: {
    maxWidth: "40%",
    color: COLOR_CRISP_BLUE,
    fontSize: 10,
    fontWeight: 500
  },
  factorView: {
    marginTop: 30
  },
  resultSubHeader: {
    color: '#333',
    fontSize: 11,
    fontFamily: 'Helvetica-Bold'
  },
  factorHeader: {
    color: '#333',
    fontSize: 18,
    fontFamily: 'Helvetica-Bold'
  },
  factorBlock: {
    marginTop: 15,
    marginBottom: 15
  },
  factorName: {
    color: '#333',
    fontSize: 12,
    fontFamily: 'Helvetica-Bold'
  },
  factorDescription: {
    color: '#888',
    fontSize: 10
  },
  section: {
    color: 'black', 
    textAlign: 'left', 
    margin: 30,
    fontSize: 12
  },
  hr: {
    height: 20,
    marginBottom: 20, 
    borderBottom: `1px solid ${COLOR_CRISP_BLUE}`
  },
  hrHalfWidth: {
    height: 20,
    width: "50%",
    marginBottom: 10
  },
  sectionHeader: {
    color: COLOR_CRISP_BLUE,
    fontSize: 18,
    fontFamily: 'Helvetica-Bold'
  },
  factorTypeHeader: {
    fontSize: 13,
    fontFamily: 'Helvetica-Bold'
  },
  factorHeader: {
    fontSize: 11,
    fontFamily: 'Helvetica-Bold'
  },
  // kind of a table
  row: {
    flexDirection: "row", 
    border: "1px solid grey",
    justifyContent: "space-between"
  },  
  rowCell: {
    width: "70%",
    borderRight: "1px solid grey",
    padding: 5,
    flexGrow: 1
  },
  rowCellShort: {
    width: "30%",
    borderRight: "1px solid grey",
    padding: 5,
    flexGrow: 1
  },
  rowCellWider: {
    width: "100%",
    borderRight: "1px solid grey",
    padding: 5,
    flexGrow: 2
  },
  rowCellText: {
    fontSize: 9,
    color: COLOR_CRISP_GREY_PRIMARY
  },
  rowCellTextBold: {
    fontSize: 10,
    color: COLOR_CRISP_GREY_PRIMARY,
    fontFamily: 'Helvetica-Bold'
  },
  rowCellText2: {
    fontSize: 8,
    color: COLOR_CRISP_GREY_PRIMARY
  },
  rowCellText3: {
    fontSize: 8,
    color: COLOR_CRISP_GREY_PRIMARY,
    marginBottom: 10
  },
  spacer: {
    height: 35
  },
  spacer20: {
    height: 20
  },
  text: {
    textAlign: 'justify',
    fontSize: 10,
    color: COLOR_CRISP_GREY_PRIMARY,
    marginBottom: 12
  },
  textBold: {
    textAlign: 'justify',
    fontSize: 10,
    color: COLOR_CRISP_GREY_PRIMARY,
    marginBottom: 12,
    fontFamily: 'Helvetica-Bold'
  },
  textBoldBlue: {
    color: COLOR_CRISP_BLUE,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold'
  },
  footer: {
    fontSize: 10,
    color: COLOR_CRISP_BLUE,
    fontFamily: 'Helvetica-Bold'
  },
  footerBand: {
    position: 'absolute',
    bottom: "3pt"    
  },
  footerTitle: {
    position: 'absolute',
    bottom: 10,
    left: 25,
    right: 0,
    textAlign: 'left',
    fontSize: 9,
    color: COLOR_CRISP_BLUE,
    fontFamily: 'Helvetica-Bold'
  },
  footerDate: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 9,
    color: COLOR_CRISP_BLUE
  },
  footerPageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 10,
    left: 0,
    right: 25,
    textAlign: 'right',
    color: 'grey',
  },

  // table style for listing factor characteristics
  table: { 
    display: "table", 
    width: "auto",     
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "15%"    
  }, 
  tableCol2: { 
    width: "85%", 
    borderStyle: "solid"    
  }, 
  tableCell: { 
    marginTop: 5, 
    marginLeft: 0,
    fontSize: 10 
  },  

  // list styles
  tableCellList: { 
    flexDirection: "column",
    marginTop: 5, 
    marginLeft: 0,
    fontSize: 10 
  },
  item: {
    flexDirection: "row",
    marginBottom: 5
  },
  itemContent: {
    flex: 1,
    fontSize: 10
  }
}

export const FACTOR_ICONS_PDF = {  
  [FACTOR_SPECIALIZATION_HAZARD]: <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M20.9453 40.0001C31.991 40.0001 40.9453 31.0458 40.9453 20.0001C40.9453 8.95443 31.991 0.00012207 20.9453 0.00012207C9.89962 0.00012207 0.945312 8.95443 0.945312 20.0001C0.945312 31.0458 9.89962 40.0001 20.9453 40.0001Z" fill="#2A66FF"/>
      <Path d="M33.7651 26.6401L22.5451 6.28008C21.8451 5.02008 20.0451 5.02008 19.3451 6.28008L8.12512 26.6401C7.46512 27.8601 8.32512 29.3401 9.72512 29.3401H32.1651C33.5451 29.3401 34.4251 27.8601 33.7651 26.6401ZM19.3051 10.9801C19.3051 10.1601 19.9851 9.48008 20.8051 9.48008C21.6251 9.48008 22.3051 10.1601 22.3051 10.9801V20.0401C22.3051 20.8601 21.6251 21.5401 20.8051 21.5401C19.9851 21.5401 19.3051 20.8601 19.3051 20.0401V10.9801ZM20.8051 27.1001C19.7251 27.1001 18.8451 26.2201 18.8451 25.1401C18.8451 24.0601 19.7251 23.1801 20.8051 23.1801C21.8851 23.1801 22.7651 24.0601 22.7651 25.1401C22.7651 26.2201 21.8851 27.1001 20.8051 27. 1001Z" fill="white"/>
      <Rect width="40" height="40" fill="white" transform="translate(0.944824)"/>
    </Svg>,
  [FACTOR_SPECIALIZATION_EXPOSURE]: <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M20.9451 40.0001C31.9908 40.0001 40.9451 31.0458 40.9451 20.0001C40.9451 8.95443 31.9908 0.00012207 20.9451 0.00012207C9.89937 0.00012207 0.945068 8.95443 0.945068 20.0001C0.945068 31.0458 9.89937 40.0001 20.9451 40.0001Z" fill="#8DBF60"/>
      <Path d="M20.8244 12.2401C17.9644 15.1001 16.6844 18.9601 16.9844 22.7001C17.0444 23.5201 17.7044 24.1801 18.5244 24.2401C22.2644 24.5401 26.1244 23.2601 28.9844 20.4001C31.8444 17.5401 33.1244 13.6801 32.8244 9.94014C32.7644 9.12014 32.1044 8.46014 31.2844 8.40014C27.5444 8.10014 23.6844 9.38014 20.8244 12.2401Z" fill="white"/>
      <Path d="M14.5249 34.9201C13.7049 34.9201 13.0249 34.2401 13.0249 33.4201V31.4201C13.0249 27.9601 14.3849 24.6801 16.8249 22.2401C17.4049 21.6601 18.3649 21.6601 18.9449 22.2401C19.5249 22.8201 19.5249 23.7801 18.9449 24.3601C17.0649 26.2401 16.0249 28.7601 16.0249 31.4201V33.4201C16.0249 34.2401 15.3449 34.9201 14.5249 34.9201Z" fill="white"/>
      <Path d="M15.4845 16.7602C17.1045 18.3802 17.8245 20.5402 17.6445 22.6602C17.6045 23.1202 17.2445 23.5002 16.7845 23.5202C14.6845 23.7002 12.5045 22.9802 10.8845 21.3602C9.26451 19.7402 8.54451 17.5802 8.72451 15.4602C8.76451 15.0002 9.12451 14.6202 9.58451 14.6002C11.6845 14.4202 13.8645 15.1402 15.4845 16.7602Z" fill="white"/>
      <Rect width="40" height="40" fill="white" transform="translate(0.944824)"/>
    </Svg>,
  [FACTOR_SPECIALIZATION_IMPACT]: <Svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M17.85 35.7C27.7083 35.7 35.7 27.7083 35.7 17.85C35.7 7.99172 27.7083 0 17.85 0C7.99169 0 0 7.99172 0 17.85C0 27.7083 7.99169 35.7 17.85 35.7Z" fill="#404649"/>
      <Path d="M23.3701 6.77002H12.4001C10.8537 6.77002 9.6001 8.02363 9.6001 9.57002V25.73C9.6001 27.2764 10.8537 28.53 12.4001 28.53H23.3701C24.9165 28.53 26.1701 27.2764 26.1701 25.73V9.57002C26.1701 8.02363 24.9165 6.77002 23.3701 6.77002Z" fill="white"/>
      <Path d="M21.4199 23.8599C21.4199 23.8599 21.3899 23.8599 21.3799 23.8599C20.8699 23.8499 20.4099 23.5399 20.1999 23.0699L17.84 17.8499L16.8499 19.9699C16.5399 20.6399 15.7399 20.9299 15.0699 20.6099C14.3999 20.2899 14.11 19.4999 14.43 18.8299L16.6599 14.0799C16.8799 13.6099 17.35 13.3099 17.87 13.3099C18.39 13.3099 18.87 13.6199 19.08 14.0999L21.5099 19.4699L26.62 9.72994C26.96 9.07994 27.77 8.81993 28.43 9.16993C29.08 9.50993 29.34 10.3199 28.99 10.9799L22.61 23.1499C22.38 23.5899 21.9199 23.8699 21.4199 23.8699V23.8599Z" fill="#404649"/>
    </Svg>,
  [FACTOR_SPECIALIZATION_VULNERABILITY]: <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">    
      <Path d="M20.9451 40.0001C31.9908 40.0001 40.9451 31.0458 40.9451 20.0001C40.9451 8.95443 31.9908 0.00012207 20.9451 0.00012207C9.89937 0.00012207 0.945068 8.95443 0.945068 20.0001C0.945068 31.0458 9.89937 40.0001 20.9451 40.0001Z" fill="#95B3FF"/>
      <Path d="M12.0055 22.1201H18.4455L16.5655 33.2401C16.5055 33.6201 16.6055 34.0001 16.8455 34.2801C17.0855 34.5601 17.4655 34.7201 17.8255 34.7201C18.2055 34.7201 18.5655 34.5401 18.7855 34.2401L29.5255 20.5201C29.7255 20.2801 29.8255 19.9601 29.7855 19.6401C29.7655 19.3201 29.6255 19.0201 29.3855 18.8201C29.1455 18.6001 28.8455 18.4801 28.5255 18.4801H22.0855L23.9655 7.36013C24.0255 6.98013 23.9255 6.60013 23.6855 6.32013C23.4455 6.04013 23.0655 5.88013 22.7055 5.88013C22.3255 5.88013 21.9655 6.06013 21.7455 6.36013L11.0055 20.0801C10.8055 20.3401 10.7055 20.6401 10.7455 20.9601C10.7655 21.2801 10.9055 21.5801 11.1455 21.7801C11.3855 22.0001 11.6855 22.1201 12.0055 22.1201Z" fill="white"/>
      <Rect width="40" height="40" fill="white" transform="translate(0.944824)"/>
    </Svg>,
  [FACTOR_SPECIALIZATION_ADAPTATION]: <Svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.9448 40C31.9905 40 40.9448 31.0457 40.9448 20C40.9448 8.9543 31.9905 0 20.9448 0C9.89913 0 0.944824 8.9543 0.944824 20C0.944824 31.0457 9.89913 40 20.9448 40Z" fill="#404649" fill-opacity="0.5"/>
    <path d="M32.5048 11.8204C32.4648 11.7204 32.3848 11.6404 32.2648 11.6204C32.1648 11.6004 32.0448 11.6204 31.9648 11.7004L28.7448 14.9204L26.1448 14.1204L25.3448 11.5204L28.5648 8.30036C28.5648 8.30036 28.6648 8.12035 28.6448 8.00035C28.6248 7.90035 28.5448 7.80036 28.4448 7.76036C26.7848 7.14036 24.9448 7.16036 23.3048 7.84036C21.6648 8.52035 20.3448 9.80035 19.6248 11.4204C18.9048 13.0404 18.8048 14.8804 19.3848 16.5604L10.0648 26.1604C9.48479 26.7404 9.16479 27.5004 9.16479 28.3204C9.16479 29.1404 9.48479 29.9004 10.0648 30.4804C10.6448 31.0604 11.4048 31.3804 12.2248 31.3804C13.0448 31.3804 13.8048 31.0604 14.3848 30.4804L23.6848 20.8804C25.3648 21.4604 27.2048 21.3804 28.8248 20.6604C30.4448 19.9404 31.7248 18.6204 32.4048 16.9604C33.0848 15.3204 33.1048 13.4804 32.4848 11.8204H32.5048ZM12.3648 30.0004C11.3648 30.0004 10.5648 29.2004 10.5648 28.2004C10.5648 27.2004 11.3648 26.4004 12.3648 26.4004C13.3648 26.4004 14.1648 27.2004 14.1648 28.2004C14.1648 29.2004 13.3648 30.0004 12.3648 30.0004Z" fill="white"/>
    <rect width="40" height="40" fill="white" transform="translate(0.944824)"/>
  </Svg>,
  [FACTOR_SPECIALIZATION_RISK]: <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20C0 31.0457 8.95431 40 20 40Z" fill="white"/>
      <Path d="M28.7213 22.4553H11.2787C9.95711 22.4553 8.88574 23.5267 8.88574 24.8483C8.88574 26.1699 9.95711 27.2413 11.2787 27.2413H28.7213C30.0429 27.2413 31.1143 26.1699 31.1143 24.8483C31.1143 23.5267 30.0429 22.4553 28.7213 22.4553Z" fill="#2A66FF"/>
      <Path d="M27.0656 13.5809C26.6063 10.3203 23.6349 7.79126 20.0001 7.79126C16.3653 7.79126 13.3939 10.3203 12.9346 13.5809L10.8535 23.6348H29.1523L27.0713 13.5809H27.0656Z" fill="#2A66FF"/>
      <Path d="M16.2178 28.761C16.2178 28.761 16.2178 28.7723 16.2178 28.778C16.2178 30.8704 17.9133 32.5659 20.0057 32.5659C22.0981 32.5659 23.7936 30.8704 23.7936 28.778C23.7936 28.7723 23.7936 28.7667 23.7936 28.761H16.2291H16.2178Z" fill="#2A66FF"/>
      <Path d="M20.4878 6.03906H19.5125C18.8611 6.03906 18.333 6.56714 18.333 7.21854V8.44338C18.333 9.09479 18.8611 9.62285 19.5125 9.62285H20.4878C21.1392 9.62285 21.6673 9.09479 21.6673 8.44338V7.21854C21.6673 6.56714 21.1392 6.03906 20.4878 6.03906Z" fill="#2A66FF"/>
      </Svg>
};
