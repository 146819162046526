import { Component } from "react";

class Usecases extends Component {

  render() {
    return <div className="wrapper-tools">
      <h3>Use cases</h3>
      <p>
        Heber, A.; Lottje, C.; Below, T.; Voss, M.; Rued, S.; Saavedra, C.; Bwalya, A.; Chikomba, P.; Bonilla Findji, O. (2024) <br/>Applying the CRISP tool in Bolivia and Zambia: Identifying climate risks and adaptation options in agricultural and food system projects. 6 p. <a href="https://hdl.handle.net/10568/149058" target="_blank">https://hdl.handle.net/10568/149058</a>
      </p>  
    </div>
  }
}
export default Usecases;