import { Component } from "react";
import crossfilter from 'crossfilter2';
import * as _ from 'lodash';
import * as d3 from 'd3';
import { 
  getAllImpactChains,
  getImpactChainsByContext,
  getAllFactorsByICModel,
  getAllRelatedFactorsByICModel,
  getImpactChainDescription,
  getFarmingSystemProperties,
  getFactorInContext,
  getFactorsInContext,
  getFactorTypes
} from '../data/DataFetcher';
import {
  getNodesAndLinks
} from '../data/DataUtils';
import { Context } from './context';
import { 
  DIMENSIONS, 
  DIMENSION_IS_ARRAY, 
  FACTOR_NAME_SPLIT_STRING, 
  MODAL_MESSAGE_IMPACT_CHAIN_SELECT, 
  VIEW_NETWORK, VIEW_HOME,
  MODAL_MESSAGE_LOADING_DATA, 
  MODAL_MESSAGE_LOADING_IMPACT_CHAIN,
  MODAL_MESSAGE_LOADING_CRISP_SESSION, 
  FACTOR_SPECIALIZATIONS,
  FARMING_SYSTEM_DIM,  
  SKIP_MODAL_MESSAGE,
  SKIP_VIEW_NETWORK
} from "../Constants";
import {
  addImpactChain,
  getImpactChainID,
  getFactorIDs,
  addRejectedFactorTypeIDs,
  getRejectedFactorTypeIDs,
  addRejectedFactorSubTypeIDs,
  getRejectedFactorSubTypeIDs
} from "../utils/url-utils";

export class Provider extends Component {

  state = {
    // flag during fetching initial data 
    modalMessage: '',
    ICId: null,
    impactChains: [],
    // use crossfilter to perform multidimensional filtering
    // and aggregations.
    // http://crossfilter.github.io/crossfilter/
    // https://github.com/crossfilter/crossfilter/wiki/API-Reference
    // Crossfilter is intended to deal with large datasets. In our case
    // we do not have large datasets, but we have datasets where some properties
    // are not a unique value, but an array of values. We could then converts
    // the data into tidy data (then we have a large dataset) or keep as it is
    // and rely on the fact that Crossfilter supports having an array of values
    // on the properties.
    // Crossfilter will save us from coding the faceting stuff when selecting items
    // and converting the data to tidy data.
    cf : null,
    // define the dimensions used to filter the tenders list
    dimensions : {},
    // filters selected
    filters: [],
    // current page (context selction, network, table)
    view: VIEW_HOME,
    //view: VIEW_NETWORK,
    // list of impact chains available from the context selection
    ICModelNames: [],
    // collection of factor relationship in the ICModel
    ICModelName: undefined,
    ICModelLinks: undefined,
    // description of the Impact chain
    ICDescription: undefined,
    // properties of the farming system such as description, total population, total area, etc.
    FarmingSystemProperties: undefined,
    //ICModelName: 'Agropastoral',  ICModelLinks: dummyNetwork,
    // highlighed factor type and subtype from the legend
    highlightedFactorType: undefined,
    highlightedFactorSubtype: undefined,
    highlightOrigin: undefined, // 'network' or 'legend',
    // list of selected factors by the user
    selectedFactors:[],    
    // hash of factor names containing long data (description, tags, bibliography)
    factorsInContext: {},
    //text search
    factorNameSearch: "",
    //link being hovered
    hoveredLink: null,
    //factor being hovered
    hoveredFactor: null,
    //rejected factor types
    rejectedFactorTypes: [],
    // rejected factor sub types,
    rejectedFactorSubTypes: [],
    // reset network zoom flag
    zoom: d3.zoom().scaleExtent([1/2, 64]),
    resetZoomFlag:false,
    onlySelectedFactorsVisible: false,
    // layout of the network
    clusteredNetworkLayout: true,
    // flag to bring back the ICNetwork react component to the DOM, so
    // it can be rendered by the function 'd3ToPng' when generating reports
    showICNetwork: false
  }


  fetchImpactChainsByContext = async(filters) => {
    return await getImpactChainsByContext(filters);    
  }

  fetchAllFactorsByICModel = async(ICModelName) => {
    return await getAllFactorsByICModel(ICModelName);
  }

  fetchAllRelatedFactorsByICModel = async(ICModelName) => {
    return await getAllRelatedFactorsByICModel(ICModelName);
  }

  fetchImpactChainDescription = async(ICModelName) => {
    return await getImpactChainDescription(ICModelName)
  }

  fetchFarmingSystemProperties = async(FarmingSystemName) => {
    return await getFarmingSystemProperties(FarmingSystemName)
  }

  fetchImpactChainByContext = async(filters, skipModalMessage = false) => {
    
    this.setState({ modalMessage: MODAL_MESSAGE_LOADING_IMPACT_CHAIN });
  
    // get all ICModelNames given the filters set in the facet browser
    let ICModelNames = await this.fetchImpactChainsByContext(filters);

    this.setState({
      ICModelNames,
      ICModelName: _.first(ICModelNames),
      modalMessage: skipModalMessage ? "" : MODAL_MESSAGE_IMPACT_CHAIN_SELECT
    });
  }

  fetchFactorFromImpactChain = async(ICModelName, defaultFactorTypes = [], skipViewNetwork = false) => {

    // when starting fetching an Impact Chain, user has set an initial
    // set of factor types to visualize. Set those not selected in
    // the provider state  
    let rejectedFactorTypes = _.isNull(defaultFactorTypes) ? [] : _.difference(FACTOR_SPECIALIZATIONS, defaultFactorTypes);
    this.setState({
      onlySelectedFactorsVisible: false,
      rejectedFactorTypes,
      modalMessage: skipViewNetwork ? MODAL_MESSAGE_LOADING_CRISP_SESSION : MODAL_MESSAGE_LOADING_DATA
    });
    
    // Get all the factors by the ICModel
    // this contains single occurrences of each factor present in the ICModel
    //const factors = await this.fetchAllFactorsByICModel(ICModelName);
    
    // Get all related factors by the ICModel (relationships by linkType)
    // array of:
    // {factorName: 'Adjust crop calendar', factorType: 'Adaptation', linkType: 'Mitigates', relFactorName: 'Reliance on rainfed agriculture', relFactorType: 'Vulnerability'}
    const factorRelationships = await this.fetchAllRelatedFactorsByICModel(ICModelName);

    const ICModelLinks = _.groupBy(
      factorRelationships, 
      (obj) => [obj.factorName, obj.relFactorName].sort().join(FACTOR_NAME_SPLIT_STRING)
    );

    // get contextual information of all the factors
    // let factors =_.chain(factorRelationships)
    //   .uniqBy('factorName')
    //   .map(o => _.pick(o, 'factorName'))
    //   .value();
    let factorsInContext = _.keyBy(
      await getFactorsInContext(ICModelName),
      'factorName'
    );

    // get also the ICModel description and related properties
    let FarmingSystemProperties = _.first(await this.fetchFarmingSystemProperties(ICModelName))
    FarmingSystemProperties.countries = FarmingSystemProperties.countries.join(", ");

    let ICModelDescription = _.first(await this.fetchImpactChainDescription(ICModelName))?.description;

    // set impact chain identifier as an URL param
    // and initial factor types
    addImpactChain(ICModelName.shortId);

    if(!_.isEmpty(rejectedFactorTypes))
      addRejectedFactorTypeIDs(rejectedFactorTypes, undefined, this.state.FACTOR_TYPE_IDS);

    // update context and set default view
    // once we have data
    this.setState({
      modalMessage:'',
      ICModelName: ICModelName.ICModelName,
      ICId: ICModelName.shortId,
      ICModelLinks,
      ICModelDescription,
      FarmingSystemProperties, 
      factorsInContext,
      view: skipViewNetwork ? this.state.view : VIEW_NETWORK
    });

    return { ICModelLinks, factorsInContext };
  }

  fetchFactorTypes = async() => {
    const factorTypes = await getFactorTypes();
    const FACTOR_TYPE_IDS =
      _.concat(
        _.map(
          _.uniqBy(factorTypes, "factorTypeName"),
          o => ({name: o.factorTypeName, id: o.factorTypeShortId})
        ),
        _.map(
          _.uniqBy(factorTypes, "factorSubtypeName"),
          o => ({name: o.factorSubtypeName, id: o.factorSubtypeShortId})
        )
      );

    let FACTOR_TYPES = {};
    factorTypes.forEach(factorType => {
      if(factorType.factorSubtypeName === '')
        FACTOR_TYPES[factorType.factorTypeName] = [];
      else 
        FACTOR_TYPES[factorType.factorTypeName].push(factorType.factorSubtypeName)
    })
    this.setState({
      FACTOR_TYPES,
      FACTOR_TYPE_IDS
    });
  }

  fetchAllImpactChains = async() => {
    const impactChains = await getAllImpactChains();
    const cf = crossfilter(impactChains);

    // define dimensions that will act as filter in the UI,
    // for each dimension, define its accessor and whether
    // is a dimension that manages arrays and not simple values
    const dimensions = {};
    DIMENSIONS.forEach(dimension => {
      dimensions[dimension] = cf.dimension(
        d => d[dimension],
        DIMENSION_IS_ARRAY(dimension)
      )
    });
    this.setState({impactChains, cf, dimensions});
    return impactChains;
  }

  fetchHoveredFactor = async(factorName, ICModelName) => {
    this.setState({
      hoveredFactor: _.isNil(factorName)? null : this.state.factorsInContext[factorName]
    });
  }

  fetchFactorInContext = async(factorName, ICModelName) => {
    const factorInContext = _.first(
      await getFactorInContext(factorName, ICModelName || this.state.ICModelName)
    );
    this.setState({factorInContext});
  }

  resetFactorInContext = async() => {
    this.setState({
      factorInContext:undefined
    })
  };

  fetchRelatedFactorsInContext = async(factors) => {
    let relatedFactorsInContext = _.cloneDeep(factors)
    for(let factor of relatedFactorsInContext) {
      let factorInContext = _.first(await getFactorInContext(factor.factorName, this.state.ICModelName));
      _.assign(factor, factorInContext);
    }
    this.setState({relatedFactorsInContext})
  }

  resetRelatedFactorsInContext = async() => {
    this.setState({
      relatedFactorsInContext:[]
    })
  };

  fetchLink = async(fromFactorLabel, toFactorLabel) => {
    let {
      ICModelLinks
    } = this.state;

    if(_.isNil(fromFactorLabel) || _.isNil(toFactorLabel))
      this.setState({ hoveredLink:null });
    else {
      /*let link = await getLink(
        this.state.ICModelName,
        fromFactorLabel, 
        toFactorLabel
      );*/
      let linkKey = [fromFactorLabel, toFactorLabel].sort().join(FACTOR_NAME_SPLIT_STRING);
      this.setState({ hoveredLink: ICModelLinks[linkKey] })
    }
  }

  updateFilters = (payload) => {
    let filters = _.cloneDeep(this.state.filters),
      dimensions = _.cloneDeep(this.state.dimensions);

    //let { filters, dimensions } = this.state;

    // update the list of filters
    let index = filters.findIndex(el => _.isEqual(el, payload));
      //el.category === payload.category && el.key === payload.key;
    
    if(index !== -1) {
      filters.splice(index, 1);
    }
    else {
      // for farming system filter, only one selection at the time
      // for countries, whatever user selects (multiple values is possible)
      if(payload.category === FARMING_SYSTEM_DIM) {
        filters = _.reject(filters, ["category", FARMING_SYSTEM_DIM])
        filters = [...filters, payload];
      } 
      else {
        filters = [...filters, payload];
      }           
    }
    // update the filter function of the dimension by:
    let keys = _(filters)
      .filter(['category', payload.category])
      .map('key')
      .value();

      if(keys.length === 0)
        dimensions[payload.category].filterAll();
      else
        dimensions[payload.category].filter(function(key) {
          return _.indexOf(keys, key) !== -1;
        });

    this.setState({filters, dimensions});
  }

  rejectFactorType = (factorType) => {
    let rejectedFactorTypes = _.cloneDeep(this.state.rejectedFactorTypes);

    // update the list of rejected factor types
    let index = rejectedFactorTypes.findIndex(ft => _.isEqual(ft, factorType));   
    if(index !== -1)
      rejectedFactorTypes.splice(index, 1);
    else
      rejectedFactorTypes = [...rejectedFactorTypes, factorType];
    
    // spread the add/remove operation
    // to the factor subtypes:
    let rejectedFactorSubTypes = _.cloneDeep(this.state.rejectedFactorSubTypes);
    // if we have added a factor type as rejected, reject all
    // its subtypes (and we remove possible duplicated subtypes)
    if(index === -1)
      rejectedFactorSubTypes = _.uniq([
        ...rejectedFactorSubTypes, 
        ...this.state.FACTOR_TYPES[factorType]
      ])
    // if we have removed a factor type from rejections, remove
    // all its factor sub types
    else
      rejectedFactorSubTypes = _.difference(
        rejectedFactorSubTypes,
        this.state.FACTOR_TYPES[factorType]
      )

    // add rejected factor types in the URL params
    addRejectedFactorTypeIDs(rejectedFactorTypes, rejectedFactorSubTypes, this.state.FACTOR_TYPE_IDS);
    this.setState({rejectedFactorTypes, rejectedFactorSubTypes});
  }

  rejectFactorSubType = (factorSubType) => {
    let rejectedFactorSubTypes = _.cloneDeep(this.state.rejectedFactorSubTypes);

    // update the list of rejected factor types
    let index = rejectedFactorSubTypes.findIndex(ft => _.isEqual(ft, factorSubType));   
    if(index !== -1)
      rejectedFactorSubTypes.splice(index, 1);
    else
      rejectedFactorSubTypes = [...rejectedFactorSubTypes, factorSubType];
    
    addRejectedFactorSubTypeIDs(rejectedFactorSubTypes, this.state.FACTOR_TYPE_IDS);
    this.setState({rejectedFactorSubTypes});
  }

  highlightFactorType = (highlightedFactorType, highlightedFactorSubtype, highlightOrigin) => {   
    this.setState({highlightedFactorType, highlightedFactorSubtype, highlightOrigin});
  }

  setFactorNameSearch = (factorNameSearch = "") => {
    this.setState({factorNameSearch})
  }
  
  /* selectFactor expects a factor obj like:
  {
    label, type,
    data: {
      factorName: "Adjust crop calendar"
      factorSubtypeName: "HumanCapitalAdaptation"
      factorTypeName: "Adaptation"
      factorShortId: 00
      linkType: "Mitigates"
      relFactorName: "Reliance on rainfed agriculture"
      relFactorSubtypeName: "HumanRelatedVulnerability"
      relFactorTypeName: "Vulnerability"
    }
  */
  selectFactor = (factor) => {
    let { selectedFactors } = this.state,
      index = _.findIndex(
        selectedFactors, 
        ['label', factor.label]
      );

    this.setState({
      selectedFactors: index === -1? 
        // add factor
        [...selectedFactors, factor] : 
        // remove factor
        _.reject(selectedFactors, ['label', factor.label])
    });
  }

  resetSelectedFactors = () => {
    this.setState({
      selectedFactors: [],
      onlySelectedFactorsVisible: false
    });
  }

  exportData = () => {
    let {
      factorsInContext,
      ICModelLinks
    } = this.state;

    return {
      factors: factorsInContext,
      links: _.values(ICModelLinks)
    }
  }

  exportDataFromSelectedFactors = () => {
    let {
      factorsInContext,
      selectedFactors,
      ICModelLinks
    }  = this.state,
    selectedFactorNames = _.map(selectedFactors, 'label');

    return {
      factors: _.pickBy(
        factorsInContext,
        (value, key) => selectedFactorNames.includes(key)
      ),
      // for links, ICModelLinks keys are factor1Name@@@factor2Name so
      // check all links where any of the factors is selected
      links: _.values(
        _.pickBy(
          ICModelLinks,
          (value, key) => !_.isEmpty(
              _.intersection(selectedFactorNames, key.split(FACTOR_NAME_SPLIT_STRING))
          )
        )
      )
    }
  }

  isFactorSelected = (factor) => {
    return !_.isUndefined(
      _.find(
        this.state.selectedFactors, 
        ['label', factor.label || factor.factorName]
      )
    );
  }

  isFactorANeighbourOfSelectedFactor = (factor) => {

    // get all my factor´s links, 
    let links = _.values(
      _.pickBy(
        this.state.ICModelLinks,
        (value, key) => key.split(FACTOR_NAME_SPLIT_STRING).includes(factor.label)
      )
    )

    // first neighbourg we find that is selected, we return true
    let retValue = false;
    links.forEach(link => {
      let neighbour = _.find(link, f => f.factorName !== factor.label);
      if(this.isFactorSelected(neighbour))
        retValue = true;
        
    })
    return retValue;
  }

  setView = (view) => {
    this.setState({view});
  }    

  setModalWindowMessage = (modalMessage = '') => {
    this.setState({modalMessage});
  }

  resetZoom = (flag = false) => {
    this.setState({resetZoomFlag:flag});
  }

  showOnlySelectedFactors = () => {
    this.setState({
      onlySelectedFactorsVisible: !this.state.onlySelectedFactorsVisible
    })
  }

  setClusteredNetworkLayout = () => {
    this.setState({
      clusteredNetworkLayout: !this.state.clusteredNetworkLayout
    })
  }

  forceICNetworkVisibility = (value) => {
    this.setState({
      showICNetwork: value
    });
  }

  // initial SPARQL queries on app bootstrap
  async componentDidMount() {
    this.fetchFactorTypes();
    let impactChains = await this.fetchAllImpactChains();
    let impactChain = undefined

    // check if we have URL parameters
    const ICID = getImpactChainID();

    if(!_.isNull(ICID)) {
      impactChain = _.find(impactChains, ["shortId", ICID]);

      // send same payload as when user selects from the IC facet browser
      // and skip the next modal window (visibilty for factor types), as
      // we continue doing SPARQL queries
      await this.fetchImpactChainByContext(
        [
          {
            category: "farmingSystem",
            key: impactChain.farmingSystem
          }
        ],
        SKIP_MODAL_MESSAGE
      );

      // once ´fetchFactorFromImpactChain´ is done, it jumps to the network view.
      // Call the function without triggering a change in the state.view,
      // update the selected factors using ids comings from URL parameters
      // and then trigger the change in the view

      const { 
        ICModelLinks, 
        factorsInContext
      } = await this.fetchFactorFromImpactChain(
        {
          ICModelName: impactChain.name,
          shortId: impactChain.shortId
        },
        null,
        SKIP_VIEW_NETWORK
      );

      // prepare an update state object so we update just once
      let updateState = {
        view: VIEW_NETWORK
      }

      // check if there are selected factors in the URLParams
      const FIDS = getFactorIDs();
      if(!_.isNull(FIDS)) {
        // get the same factor object that the network is using, that is,
        // the one from getNodesAndLinks
        const { nodes } = getNodesAndLinks(ICModelLinks, factorsInContext);

        let selectedFactors = _.filter(nodes, node => FIDS.includes(node.data.factorShortId));
        if(!_.isEmpty(selectedFactors))
          updateState = {
            selectedFactors,
            ...updateState
          };          
      }

      // check if there are rejected factor types
      const FTIDS = getRejectedFactorTypeIDs(this.state.FACTOR_TYPE_IDS);
      if(!_.isNull(FTIDS))
        updateState = {
          rejectedFactorTypes: FTIDS,
          ...updateState
        };

      // check if there are rejected factor types
      const FSTIDS = getRejectedFactorSubTypeIDs(this.state.FACTOR_TYPE_IDS);
      if(!_.isNull(FSTIDS))
        updateState = {
          rejectedFactorSubTypes: FSTIDS,
          ...updateState
        };

      // update the state by moving the to Network view, with
      // any further state regarding factor selection and/or
      // factor type rejection
      this.setState(updateState);
    }
  }

  render() {
    return (
      <Context.Provider value={{
        ...this.state,
        updateFilters: this.updateFilters,
        fetchImpactChainByContext: this.fetchImpactChainByContext,
        fetchFactorFromImpactChain: this.fetchFactorFromImpactChain,
        fetchFactorInContext: this.fetchFactorInContext,
        fetchHoveredFactor: this.fetchHoveredFactor,
        resetFactorInContext: this.resetFactorInContext,
        fetchRelatedFactorsInContext: this.fetchRelatedFactorsInContext,
        resetRelatedFactorsInContext: this.resetRelatedFactorsInContext,
        highlightFactorType: this.highlightFactorType,
        rejectFactorType: this.rejectFactorType,
        rejectFactorSubType: this.rejectFactorSubType,
        selectFactor: this.selectFactor,
        resetSelectedFactors: this.resetSelectedFactors,
        isFactorSelected: this.isFactorSelected,
        isFactorANeighbourOfSelectedFactor: this.isFactorANeighbourOfSelectedFactor,
        fetchLink: this.fetchLink,
        setView: this.setView,
        setModalWindowMessage: this.setModalWindowMessage,
        // export data functions
        exportDataFromSelectedFactors: this.exportDataFromSelectedFactors,
        exportData: this.exportData,
        setFactorNameSearch: this.setFactorNameSearch,
        resetZoom: this.resetZoom,
        showOnlySelectedFactors: this.showOnlySelectedFactors,
        setClusteredNetworkLayout: this.setClusteredNetworkLayout,
        forceICNetworkVisibility: this.forceICNetworkVisibility
      }}>
        {this.props.children}
      </Context.Provider>
    )    
  }
}