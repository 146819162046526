import { Component } from "react";
import * as _ from 'lodash';
import { 
  FACTOR_ICONS, 
  FACTOR_ICON_SIZE, 
  FACTOR_VISIBLE,
  FACTOR_NOT_VISIBLE,
  HIGHLIGHT_ORIGIN_LEGEND, 
  FACTOR_VISIBILITY,
  FACTOR_TYPES_SORTED
} from '../../Constants';
import { Context } from "../../context/context";
import { enhanceFactorSubtype, enhanceLinkType } from "../../utils/label-utils";

import { Badge, Button, Form, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';

class NetworkLegend extends Component {

  static contextType = Context;

  constructor(props) {
    super(props);
    // whether we show first level hierarchy of specialization or second level
    this.FACTOR_MAIN_SPECIALIZATIONS = 1;
    this.FACTOR_SUB_SPECIALIZATIONS = 2;

    this.state = {
      factorHierarchyLevel: this.FACTOR_SUB_SPECIALIZATIONS,
      factorSearchName: ''
    }
  }
  


  showOnlyMainFactors = () => {
    return this.state.factorHierarchyLevel === this.FACTOR_MAIN_SPECIALIZATIONS;
  }



  render () {
    let {
      highlightFactorType,
      highlightedFactorType,
      highlightedFactorSubtype,
      rejectFactorType,
      rejectFactorSubType,
      rejectedFactorTypes,
      rejectedFactorSubTypes,
      setFactorNameSearch,
      resetZoom,
      FACTOR_TYPES,
      hoveredFactor,
      hoveredLink
    } = this.context;
  
    let {
      factorSearchName
    } = this.state;

    const HEIGHT_SUBFACTOR_TEXT = 15;
    
    return <div>
              <div className="panel" style={{opacity:hoveredFactor?0.3:1}}>
                <p className="info">Find factors searching by text:</p>
                <InputGroup className="mb-3" size="sm">
                  <Form.Control
                    value={factorSearchName}
                    onChange={(event) => {
                      setFactorNameSearch(
                        event.target.value.length >= 3 ? event.target.value : ''
                      );
                      this.setState({
                        factorSearchName: event.target.value
                      })
                    }}
                    placeholder="Type text to search..."
                    aria-label="text search"
                    aria-describedby="basic-addon2"
                  />
                  <Button
                    onClick={() => {
                      this.setState({factorSearchName:''});
                      setFactorNameSearch();
                    }}
                    variant="outline-secondary"
                    id="button-addon2">
                    Clear
                  </Button>
                </InputGroup>
              
                <div className="info help">
                  <div className="hoverable" onClick={() => resetZoom(true)}>
                    <img src="./images/zoom-center.png" alt=""/>Center network
                  </div>                  
                   <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Popover id={`popover-positioned`}>
                                  <Popover.Body>                                    
                                        Use the legend to show/hide impact chain components and their sub-categories. <br/>Hover to highlight them in the impact chain.                                    
                                  </Popover.Body>
                                </Popover>
                        }
                      >
                        <div className="hoverable">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                        </svg>
                        <span style={{paddingLeft: '5px'}}>Legend</span>
                        </div>
                    </OverlayTrigger>
                </div>
                {
                  FACTOR_TYPES_SORTED.map( factorType => <svg 
                    key={factorType}
                    width='275' 
                    // determine total height for the svg containing each legend entry
                    // it will vary whether we show sub type of factor or not
                    height={FACTOR_ICON_SIZE*2 + (this.showOnlyMainFactors()? 0 : FACTOR_TYPES[factorType].length*HEIGHT_SUBFACTOR_TEXT)}
                    >
                      <image xlinkHref={
                            FACTOR_VISIBILITY[rejectedFactorTypes.includes(factorType)? FACTOR_NOT_VISIBLE : FACTOR_VISIBLE]
                          }
                          x={FACTOR_ICON_SIZE+3}
                          y={FACTOR_ICON_SIZE/2}
                          width={FACTOR_ICON_SIZE}
                          height={FACTOR_ICON_SIZE}/>
                      <g transform="translate(35, 0)" opacity={rejectedFactorTypes.includes(factorType)? 0.25 : 1}>
                        <image xlinkHref={FACTOR_ICONS[factorType]}
                          x={FACTOR_ICON_SIZE/2}
                          y={FACTOR_ICON_SIZE/2}
                          width={FACTOR_ICON_SIZE}
                          height={FACTOR_ICON_SIZE}/>
                        <text className={`factor ${_.isEqual(highlightedFactorType, factorType)? 'highlighted':''} ${rejectedFactorTypes.includes(factorType)? 'disabled':'enabled'}`}
                          x={FACTOR_ICON_SIZE*2}
                          y={FACTOR_ICON_SIZE}
                          onClick={() => rejectFactorType(factorType)}
                          onMouseOver={() => {
                              // only active if it is selected
                              if(!rejectedFactorTypes.includes(factorType))
                                highlightFactorType(factorType, undefined, HIGHLIGHT_ORIGIN_LEGEND)
                            }
                          }
                          onMouseOut={() => {
                              // only active if it is selected
                              if(!rejectedFactorTypes.includes(factorType))
                                highlightFactorType(undefined, undefined, HIGHLIGHT_ORIGIN_LEGEND)
                            }
                          }>
                            {factorType}
                        </text>

                        { // show subtypes of the factor?
                          !this.showOnlyMainFactors() && FACTOR_TYPES[factorType]
                            .map( (factorSubType, i) => <g key={i}>                        
                                <g transform="translate(0, 2)" opacity={rejectedFactorSubTypes.includes(factorSubType)? 0.25 : 1}>
                                  <image xlinkHref={FACTOR_VISIBILITY[rejectedFactorSubTypes.includes(factorSubType)? FACTOR_NOT_VISIBLE : FACTOR_VISIBLE]}
                                    x={FACTOR_ICON_SIZE+8}
                                    y={FACTOR_ICON_SIZE*1.5 + HEIGHT_SUBFACTOR_TEXT*i}
                                    width={FACTOR_ICON_SIZE * 0.75}
                                    height={FACTOR_ICON_SIZE * 0.75}
                                  />
                                  <text                           
                                    className={`subfactor ${_.isEqual(highlightedFactorSubtype, factorSubType)? 'highlighted':''} ${rejectedFactorTypes.includes(factorType)? 'disabled':'enabled'}`}
                                    x={FACTOR_ICON_SIZE*2.5} 
                                    y={FACTOR_ICON_SIZE*2 + HEIGHT_SUBFACTOR_TEXT*i}
                                    onClick={() => rejectFactorSubType(factorSubType)}
                                    onMouseOver={() => highlightFactorType(factorType, factorSubType, HIGHLIGHT_ORIGIN_LEGEND)}
                                    onMouseOut={() => highlightFactorType()}>
                                    { //i.e., transform HumanCapitalAdaptation to Human Capital
                                      enhanceFactorSubtype(factorType, factorSubType)
                                    }
                                  </text>
                                </g>                        
                              </g>                      
                            )}
                      </g>
                    </svg>
                  ) 
                }
              </div>  
              {
                hoveredFactor && <div className="panel">
                <div>
                  <p className="name">
                    {hoveredFactor.factorName}                  
                  </p>
                  <p className="contextual-description">
                    {hoveredFactor.contextualDescription}                  
                  </p>
                  {/*
                  <ul>
                    {
                      hoveredFactor?.resources && 
                      hoveredFactor?.resources
                        .split(';')
                        .map(resource => {
                          let citationUrl = resource.split(FACTOR_NAME_SPLIT_STRING);
                          return _.isEmpty(citationUrl[1]) ?
                            <li> {citationUrl[0]} </li>
                            :
                            <li><a href={citationUrl[1]} rel="noreferrer" target="_blank">{citationUrl[0]}</a></li>;
                        })                            
                    }
                  </ul>
                  */}
                  <p>
                    {
                      hoveredFactor.tags && hoveredFactor.tags.split(';').map((tag, index) => <Badge key={index} pill bg='info'>{tag}</Badge>)
                    }
                  </p>
                  </div>
              </div>      
              }
              {/* Remember that the link has two items, each items has the same factor
                  but with a different direction (i.e., 'Exposes', 'isExposedTo'),
                  so just pick when displaying the info 
              */}
              {

                hoveredLink && <div className="panel">
                <div>
                  <p className="name">
                    {
                      _.join(
                        [
                          _.first(hoveredLink).factorName,
                          enhanceLinkType(_.first(hoveredLink).linkType),
                          _.first(hoveredLink).relFactorName,
                        ],
                        " "
                      )
                    }                  
                  </p>
                  <p className="contextual-description">
                    {_.first(hoveredLink).contextualDescription}                  
                  </p>
                  <p>
                    {
                      _.first(hoveredLink).tags && _.first(hoveredLink).tags.split(';').map((tag, index) => <Badge key={index} pill bg='info'>{tag}</Badge>)
                    }
                  </p>
                  </div>
              </div>      
              }
              
    </div>
  }
}

export default NetworkLegend;