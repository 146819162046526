import { Component, Fragment } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Modal } from 'react-bootstrap';
import d3ToPng from 'd3-svg-to-png';
import {
  MODAL_MESSAGE_GENERATING_IMAGE, 
  VIEW_CONTEXT_SELECTION, 
  VIEW_HOME, 
  VIEW_NETWORK, 
  VIEW_TABLE, 
  STYLE_SHEET_PDF,
  FACTOR_SORT_ORDER,
  LABELS,
  FARMING_SYSTEM_KEY_CHARACTERISTICS,
  MODAL_MESSAGE_GENERATING_REPORT,
  URL_IMAGES,
  FACTOR_NAME_SPLIT_STRING,
  VIEW_BACKGROUND,
  ALL,
  SELECTED,
  VIEW_OTHER_TOOLS,
  VIEW_USER_GUIDE,
  VIEW_VIDEOS,
  VIEW_USE_CASES
} from '../../Constants';
import { Context } from "../../context/context";
import { getNodesAndLinks } from '../../data/DataUtils';
import * as _ from 'lodash';
import {
  Svg,
  Document, 
  Page, 
  Text, 
  pdf, 
  View,
  Rect,
  Image,
  Link,
} from '@react-pdf/renderer';
import { saveAs
 } from "file-saver";
import { colorize, enhanceFactorSubtype, enhanceLinkType, pluralize } from "../../utils/label-utils";
import { Button } from "react-bootstrap";

class SubHeaderBar extends Component {
  
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      showModalShareLink: false
    }
  }

generatePdfDocument = async (networkImage, dataSelection) => {
  let {
    ICModelName,
    ICModelLinks,
    selectedFactors,
    setModalWindowMessage,
    factorsInContext,
    filters,
    FarmingSystemProperties,
    ICModelDescription
  } = this.context;

  let selectedFactorNames = null;
  if (dataSelection === SELECTED) {
    selectedFactorNames = []
    for (const f of selectedFactors) {
      selectedFactorNames.push(f.label);
    }
  }

  const action='generate-report';
  await fetch('log', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-Log': JSON.stringify({action, ICModelName, dataSelection, selectedFactorNames})
    },
    body: ''
  });

  // get only the nodes (factors) from the network
  // here we ommit the list of rejected factors, it´s 
  // a filter that only applies in the network
  let data = getNodesAndLinks(
      ICModelLinks,
      factorsInContext,
      [],
      [],
      dataSelection === SELECTED ? selectedFactors : []
    ),
    // expection on how to manage an empty selectedFactors
    // array: when empty, the function ´getNodesAndLinks´
    // returns everything. Here is empty we want to render nothing
    nodes = dataSelection === SELECTED && _.isEmpty(selectedFactors) ? [] : data.nodes,
    links = dataSelection === SELECTED && _.isEmpty(selectedFactors) ? [] : data.links,
    // group nodes by factor type
    factorsByType = _(nodes)
      .map('data')
      // enrich factor with context data
      .map(o => _.merge(o, factorsInContext[o.factorName]))
      .groupBy('factorTypeName')
      .value(),
    // get factor types, sorted
    factorTypes = _(_.keys(factorsByType))
    .map(key => ({key, order: FACTOR_SORT_ORDER[key]}))
    .sortBy('order')
    .map('key')
    .value()
    .reverse();


  const blob = await pdf((
    <Document>
    <Page style={STYLE_SHEET_PDF.page} size="A4">
      <View fixed style={STYLE_SHEET_PDF.header}>
        <Svg viewBox="0 0 100% 7pt">
          <Rect width="100%" height="7pt" fill="#2B66FF"/>
        </Svg>
        <Image style={STYLE_SHEET_PDF.headerLogo} source={`${URL_IMAGES}/logo-white.png`}/>
        <Text style={STYLE_SHEET_PDF.headerText}>CRISP</Text>
        <Image style={STYLE_SHEET_PDF.headerLogoBMZ} source={`${URL_IMAGES}/logoBMZ.png`}/>
        <Image style={STYLE_SHEET_PDF.headerLogoGIZ} source={`${URL_IMAGES}/logoGIZ.png`}/>
      </View>
      <View style={STYLE_SHEET_PDF.section}>
        <Text style={STYLE_SHEET_PDF.subheader}>
          CRISP - Climate Risk Planning & Managing Tool
          for Development Programmes in Agri-food Systems 
          beta version 0.1
        </Text>

        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          Climate Risk Planning and Managing Report
        </Text>
        <View style={STYLE_SHEET_PDF.spacer}/>
        <View style={STYLE_SHEET_PDF.row}>
          <View style={STYLE_SHEET_PDF.rowCell}>
            <Text style={STYLE_SHEET_PDF.rowCellText}>Produced on:</Text>
          </View>
          <View style={STYLE_SHEET_PDF.rowCell}>
            <Text style={STYLE_SHEET_PDF.rowCellText}>Date:</Text>
          </View>
        </View>
        <View style={STYLE_SHEET_PDF.spacer}/>
        <Text style={STYLE_SHEET_PDF.text}>
          <Text style={STYLE_SHEET_PDF.textBoldBlue}>CRISP</Text> provides an entry point for agricultural, rural development and food and nutrition security projects for an initial, simple and quick exploration of climate risks.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Using the impact chain methodology (Zebisch et al., 2017) it helps to understand relevant climate risks for Agri-food systems in a given farming system.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          The CRISP tool depicts potential impact chains by visualising causal relationships between hazard, exposure, vulnerability, impact and climate risks and allows to review relevant adaptation options (“Adaptation to what”).
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          CRISP supports you to mainstream climate risk considerations into your project design and implementation by articulating and evaluating adaptation hypotheses (“Adaptation how”).
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          With CRISP you get insights to inform your project monitoring & evaluation plan. CRISP will identify adaptation options of interest and show which vulnerabilities and impacts are being addressed (“Adaptation tracking”)
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          The structured knowledge provided by CRISP is backed up with peer-reviewed literature. The identified entry points for climate risk management can be used for subsequent in-depth studies, as required.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          The CRISP website provides guidance on additional science-driven approaches and tools available to support climate actions through your project implementation. This website will provide you with references to relevant tools and guidelines that support you in adaptation implementation (“Adaptation implementation”).
        </Text>
        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          Scope of analysis
        </Text>
        <View style={STYLE_SHEET_PDF.spacer}/>
        {
          _(filters)
            .groupBy('category')
            .toPairs()
            .value()
            .map( (arr, i) => 
              <View key={i} style={STYLE_SHEET_PDF.row}>
                <View style={STYLE_SHEET_PDF.rowCell}>
                  <Text style={STYLE_SHEET_PDF.rowCellText}>{LABELS[arr[0]]}:</Text>
                </View>
                <View style={STYLE_SHEET_PDF.rowCell}>
                  <Text style={STYLE_SHEET_PDF.rowCellText}>
                    {arr[1].map(item => item.key).join(", ")}
                  </Text>
                </View>
              </View>
            )
        }
        <View style={STYLE_SHEET_PDF.spacer} break/>
        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          The context: Farming System description
        </Text>
        <View style={STYLE_SHEET_PDF.spacer}/>
        <Text style={STYLE_SHEET_PDF.text}>
          {FarmingSystemProperties.description}
        </Text>

        <View style={STYLE_SHEET_PDF.spacer}/>
        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          Farming System key characteristics
        </Text>
        <View style={STYLE_SHEET_PDF.spacer}/>
        {
          _.toPairs(FARMING_SYSTEM_KEY_CHARACTERISTICS).map( (arr, i) => 
            <View key={i} style={STYLE_SHEET_PDF.row}>
              <View style={STYLE_SHEET_PDF.rowCell}>
                <Text style={STYLE_SHEET_PDF.rowCellTextBold}>
                  {FARMING_SYSTEM_KEY_CHARACTERISTICS[arr[0]]}:
                </Text>
              </View>
              <View style={STYLE_SHEET_PDF.rowCell}>
                <Text style={STYLE_SHEET_PDF.rowCellText}>
                  {FarmingSystemProperties[arr[0]] || "--"}
                </Text>
              </View>
            </View>
          )
        }        
        <View style={STYLE_SHEET_PDF.spacer} break/>
        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          Result
        </Text>
        <View style={STYLE_SHEET_PDF.spacer20}/>
        <Text style={STYLE_SHEET_PDF.resultSubHeader}>
          Summary: Farming system Climate Impact Chain
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          {ICModelDescription}
        </Text>
        <View style={STYLE_SHEET_PDF.spacer} break/>
        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          {dataSelection === SELECTED? 'Selected ':''}Impact chain factors and relationships - graph view
        </Text>
        <View style={STYLE_SHEET_PDF.spacer20}/>
        <Text style={STYLE_SHEET_PDF.textBold}>          
          {
            ['Showing', 
              dataSelection === SELECTED? 'selected': 'all', 
              'data'
            ].join(' ')
          }
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          {
            dataSelection === SELECTED ? 'This diagram below reflects the elements of the impact chain associated with the farming systems that were selected during online use of the CRISP tool. Only the factors selected online appear below. This diagram illustrates how these factors are interrelated.' : 'This diagram below reflects all the elements of the impact chain associated with the farming systems. This diagram illustrates how all these factors are interrelated.'
          }
        </Text>        
        <Image src={networkImage}/>
        <View break/>
        {
          factorTypes.map( (factorType, i) => <View key={i} style={STYLE_SHEET_PDF.factorView}>
            <View style={{flexDirection: "column", alignItems: "left"}}>
              <View style={[STYLE_SHEET_PDF.hrHalfWidth, {borderBottom: `1px solid ${colorize(factorType)}`}]}/>
              <Text style={[STYLE_SHEET_PDF.factorTypeHeader, {color: colorize(factorType)}]}>
                {pluralize(factorType)}
              </Text>               
            </View>
            <View style={STYLE_SHEET_PDF.spacer20}/>            
            {
              factorsByType[factorType].map((factor, j) => <View 
              key={j}
              wrap={false}
              >
                <View>
                  <Text style={STYLE_SHEET_PDF.factorHeader}>
                  {factor.factorName}
                  </Text>
                </View>
                <View style={STYLE_SHEET_PDF.table}>
                  <View style={STYLE_SHEET_PDF.tableRow}>
                    <View style={STYLE_SHEET_PDF.tableCol}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                        Type:
                      </Text>
                    </View>
                    <View style={STYLE_SHEET_PDF.tableCol2}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                      {enhanceFactorSubtype(factor.factorTypeName, factor.factorSubtypeName)}
                      </Text>
                    </View>
                  </View>

                  <View style={STYLE_SHEET_PDF.tableRow}>
                    <View style={STYLE_SHEET_PDF.tableCol}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                        Description:
                      </Text>
                    </View>
                    <View style={STYLE_SHEET_PDF.tableCol2}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                      {factor.contextualDescription}
                      </Text>
                    </View>
                  </View>

                  <View style={STYLE_SHEET_PDF.tableRow}>
                    <View style={STYLE_SHEET_PDF.tableCol}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                        Links:
                      </Text>
                    </View>
                    <View style={STYLE_SHEET_PDF.tableCol2}>
                      <View style={STYLE_SHEET_PDF.tableCellList}>
                      {
                        _(links)
                          .filter(['source', factor.factorName])
                          // a link is { source , target, type} where type is a 2-length array containing
                          // data about the 2 directions of the link. 1st direction has our factor as source
                          // and 2nd direction has our factor as target, pick the first
                          .map(obj => obj.type[0])
                          .uniqBy('relFactorName')
                          .value()
                          .map((link, i) =><View key={i} style={STYLE_SHEET_PDF.item}>
                              <Text style={STYLE_SHEET_PDF.itemContent}>
                                {_.capitalize(enhanceLinkType(link.linkType))} "{link.relFactorName}" ({link.relFactorTypeName})
                              </Text>
                            </View>
                            )
                      }
                      </View>
                    </View>
                  </View>

                  <View style={STYLE_SHEET_PDF.tableRow}>
                    <View style={STYLE_SHEET_PDF.tableCol}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                        Tags:
                      </Text>
                    </View>
                    <View style={STYLE_SHEET_PDF.tableCol2}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                      {factor?.tags && factor?.tags.split(';').join(", ")}
                      </Text>
                    </View>
                  </View>

                  <View style={STYLE_SHEET_PDF.tableRow}>
                    <View style={STYLE_SHEET_PDF.tableCol}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                        Resources:
                      </Text>
                    </View>
                    <View style={STYLE_SHEET_PDF.tableCol2}>
                      <Text style={STYLE_SHEET_PDF.tableCell}>
                      {factor?.resources && factor?.resources.split(';').map((resource, i) => {
                        let citationUrl = resource.split(FACTOR_NAME_SPLIT_STRING);
                        return _.isEmpty(citationUrl[1]) ?
                          citationUrl[0] + ", "
                          :
                          <Link key={i} src={citationUrl[1]}>{citationUrl[0]}</Link>;
                      })}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={STYLE_SHEET_PDF.spacer20}/>                  
              </View>              
              )
            }
          </View>)
        }
        <View style={STYLE_SHEET_PDF.spacer} break/>
        <View style={STYLE_SHEET_PDF.hr}/>
        <Text style={STYLE_SHEET_PDF.sectionHeader}>
          Results interpretation
        </Text>
        <View style={STYLE_SHEET_PDF.spacer20}/>
        <Text style={STYLE_SHEET_PDF.textBold}>
          1. The following question will help you interpret the results if your aim is to build/validate the climate narrative of your project or programme and identify a adaptation hypothesis.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          If you want to ANSWER THE QUESTION:
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Which are the main climate-related, impacts, vulnerabilities and/or potential adaptation options relevant to the farming system/country and commodity in which my agricultural development project will be implemented?
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          ...then detailed explanations should be developed pointing out, for example, the importance of looking at the cascading impacts.flect on?)
        </Text>
        <View style={STYLE_SHEET_PDF.spacer20}/>
        <Text style={STYLE_SHEET_PDF.textBold}>
          2. If you would like to interpret the results to validate the adaptation options your project is promoting and their relevance to the main climatic hazards and impacts ...
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Use the results in the graph view and table to ANSWER THE QUESTIONS:
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          a. Are the adaptation options promoted by my project responding to all the main climatic hazards and impacts affecting the specific farming system where they are implemented?
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          b. Are there additional suitable adaptation options my project is not yet implementing and that would be worth considering?
        </Text>
        <Text style={STYLE_SHEET_PDF.textBold}>
          Bibliographic references:
        </Text>    
        <Text style={STYLE_SHEET_PDF.text}>
          Dixon, J., Garrity, D.P., Boffa, J.-M., Williams, T.O., Amede, T., Auricht, C., Lott, R., Mburathi, G. (Eds.), 2019. Farming Systems and Food Security in Africa: Priorities for Science and Policy under Global Change, 1st ed. Routledge, New York : Routledge, 2019. https://doi.org/10.4324/9781315658841
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Dixon, J., Gibbon, D.P., Gulliver, A., Hall, M., 2001. Farming systems and poverty: improving farmers’ livelihoods in a changing world. FAO ; World Bank, Rome : Washington, D.C.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Organisation des Nations Unies pour l’alimentation et l’agriculture (Ed.), 2008. An international technical workshop investing in sustainable crop intensification: the case for improving soil health FAO, Rome: 22-24 july 2008, Integrated crop management. FAO, Rome.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Zebisch, M., Schneiderbauer, S., Renner, K., Below, T., Brossmann, M., Ederer, W., Schwan, S., 2017. Risk Supplement to the Vulnerability Sourcebook. Guidance on how to apply the Vulnerability Sourcebook’s approach with the new IPCC AR5 concept of climate risk. GIZ, Bonn.
        </Text>
        <Text style={STYLE_SHEET_PDF.text}>
          Zeufack, A.G., Calderon, C., Ntumba Kabundi, A., Kubota, M., Korman, V., Canales, C.C., 2021. Africa’s Pulse, No. 23, October 2021. The World Bank. https://doi.org/10.1596/978-1-4648-1805-9
        </Text>
      </View>      
      
      <Svg style={STYLE_SHEET_PDF.footerBand} viewBox="0 0 100% 6pt" fixed>
        <Rect width="100%" height="6pt" fill="#F6F6F6"/>
      </Svg>
      <Text style={STYLE_SHEET_PDF.footerTitle} fixed>
        Climate Risk Planning and Managing Report
      </Text>
      <Text style={STYLE_SHEET_PDF.footerDate} fixed>
        As of: {(new Date()).toLocaleDateString("en-US")}
      </Text>
      <Text
        style={STYLE_SHEET_PDF.footerPageNumber}
        render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)}
        fixed
      />
    </Page>
  </Document>
  )).toBlob();
  
  setModalWindowMessage();

  saveAs(blob, `CRISP-report-${(new Date()).toLocaleDateString("en-US").replaceAll('/','-')}.pdf`);
};

  render() {
    let { showModalShareLink } = this.state;
    let {
      ICModelLinks,
      setModalWindowMessage,
      exportDataFromSelectedFactors,
      exportData,
      forceICNetworkVisibility
    } = this.context;

    let data2JSON = (data, filename) => {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(data, null, 2)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `${filename}.json`;
      link.click();
    };

    let generateReport = (dataSelection) => {
      setModalWindowMessage(MODAL_MESSAGE_GENERATING_REPORT);

      // script to execute the survery monkey thing (it's injected into the window object in the index.html template)
     window.surveymonkeyScript(window,document,"script","smcx-sdk");
      
      // force the Network to be visible in the DOM, so it can be rendered
      // by the d3ToPng function. Once we are done, hide it again if we
      // we were in a page different from the network
      forceICNetworkVisibility(true);
      setTimeout(() => {                  
        d3ToPng('#impact-chain-network', 'impact-chain-network', {
          scale: 1,
          format: 'png',
          quality: 1,
          download: false,
          // ignore the options menu of factors
          ignore: '.contextual-menu'
        })
        .then(fileData => {
          this.generatePdfDocument(fileData, dataSelection);
          forceICNetworkVisibility(false);
        });
      }, 600);
    };

    return (
      <Fragment>
        <Modal
          show={showModalShareLink}
          onHide={() => { this.setState({showModalShareLink: false}) }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share CRISP link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Copy and share the link below to share your current CRISP session.
            </p>
            <p>
            Anyone using this link will open the tool, with the same context-specific impact chain data, selected factors and their relationships.
            </p>
            <strong>Shareable link:</strong>
            <textarea rows="4" disabled style={{resize: "none", width: "100%", overflowY: "scroll", padding: "10px"}}>{window.location.href}</textarea>
          </Modal.Body>        
        </Modal>
        <Navbar bg="light" variant="light" className='crisp-navbar-subheader'>
          <Container fluid className='navbar-sub-header'>
            <Nav className="me-auto">          
              <Nav.Link
                active={this.props.view === VIEW_HOME}
                onClick={() => this.props.changeView(VIEW_HOME)}>
                Home
              </Nav.Link>
              <Nav.Link
                active={this.props.view === VIEW_BACKGROUND}
                onClick={() => this.props.changeView(VIEW_BACKGROUND)}>
                The Basics
              </Nav.Link>
              <Nav.Link
                active={this.props.view === VIEW_CONTEXT_SELECTION}
                onClick={() => this.props.changeView(VIEW_CONTEXT_SELECTION)}>
                1. Context selection
              </Nav.Link>
              <Nav.Link
                disabled={_.isEmpty(ICModelLinks)}
                active={this.props.view === VIEW_NETWORK}
                onClick={() => this.props.changeView(VIEW_NETWORK)}>
                2. Impact chain network
              </Nav.Link>
              <Nav.Link
                disabled={_.isEmpty(ICModelLinks)}
                active={this.props.view === VIEW_TABLE}
                onClick={() => this.props.changeView(VIEW_TABLE)}>
                3. Impact chain table
              </Nav.Link>
            </Nav>
            <Nav className="justify-content-end subheader-right-menu">
              <Nav.Link>
                <Button size="sm" variant="outline-secondary" onClick={() => this.props.changeView(VIEW_USER_GUIDE)}>User Guide</Button>
              </Nav.Link>
              <Nav.Link>
                  <Button disabled={[
                        VIEW_CONTEXT_SELECTION, 
                        VIEW_HOME, 
                        VIEW_BACKGROUND, 
                        VIEW_OTHER_TOOLS, 
                        VIEW_USER_GUIDE,
                        VIEW_VIDEOS,
                        VIEW_USE_CASES
                      ].includes(this.props.view)}
                    size="sm"
                    variant={this.props.view === VIEW_CONTEXT_SELECTION || this.props.view === VIEW_HOME? "secondary" : "success"}
                    onClick={() => {this.setState({showModalShareLink: true})}}>
                      Share link
                  </Button>
              </Nav.Link>
              <Nav.Link>
                <DropdownButton
                  disabled={[
                    VIEW_CONTEXT_SELECTION, 
                    VIEW_HOME, 
                    VIEW_BACKGROUND, 
                    VIEW_OTHER_TOOLS, 
                    VIEW_USER_GUIDE,
                    VIEW_VIDEOS,
                    VIEW_USE_CASES
                  ].includes(this.props.view)}
                  variant={this.props.view === VIEW_CONTEXT_SELECTION || this.props.view === VIEW_HOME? "secondary" : "success"}
                  align="end"
                  size="sm"            
                  title="Export"
                  id="dropdown-menu-align-end"
                > 
                  <Dropdown.Header style={{fontSize: '1rem', fontWeight: '400', color: 'var(--bs-dropdown-link-color)'}}>Generate report:</Dropdown.Header>
                  <Dropdown.Item style={{fontSize: '.875rem', marginLeft: '.5rem'}} onClick={() => {generateReport(SELECTED);}}>
                    Tailored impact chain
                  </Dropdown.Item>
                  <Dropdown.Item style={{fontSize: '.875rem', marginLeft: '.5rem'}} onClick={() => {generateReport(ALL);}}>
                    Full impact chain
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header style={{fontSize: '1rem', fontWeight: '400', color: 'var(--bs-dropdown-link-color)'}} title="Export a JSON file with network factors, their links and properties, for use in JSON-aware editors, tools, or ad-hoc software (for technical users)">Network as JSON:</Dropdown.Header>
                  <Dropdown.Item
                    style={{fontSize: '.875rem', marginLeft: '.5rem'}}
                    onClick={() => {
                      data2JSON(exportDataFromSelectedFactors(), 'impact-chain-data-selection');
                    }}
                    eventKey="2">
                      Tailored impact chain
                    </Dropdown.Item>
                  <Dropdown.Item
                    style={{fontSize: '.875rem', marginLeft: '.5rem'}}
                    onClick={() => {
                      data2JSON(exportData(), 'impact-chain-data');
                    }}
                    eventKey="3">
                      Full impact chain
                  </Dropdown.Item>            
                  <Dropdown.Divider />
                  <Dropdown.Item
                    disabled={this.props.view !== VIEW_NETWORK}
                    onClick={() => {              
                        setModalWindowMessage(MODAL_MESSAGE_GENERATING_IMAGE);
                        setTimeout(() => {
                          d3ToPng('#impact-chain-network', 'impact-chain-network', {
                            scale: 1,
                            format: 'png',
                            quality: 1,
                            download: true,
                            // ignore the options menu of factors
                            ignore: '.contextual-menu'
                          })
                          .then(fileData => { setModalWindowMessage();});
                        }, 300);
                      }
                    }
                    eventKey="1">
                      Network as image
                  </Dropdown.Item>
                </DropdownButton>
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </Fragment>
    );
  }  
}

export default SubHeaderBar;