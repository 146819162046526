import { Component, Fragment } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { toolsData } from "./tools-data";
import * as _ from "lodash";

class Background extends Component {

  render() {
    // some columns use a free text filrer and some other use a list of defined values as filter
    // For the later, get a list of unique values for each.
    const fieldsWithSelectFilter = [
      'Project phase/component',
      'Target sector',
      'Theme',
      'Type of resource'
    ];
    const optionsByField = _.mapValues(
        _.keyBy(fieldsWithSelectFilter),
      (value, key) => (
        _.chain(toolsData)
          .uniqBy(key)
          .map(o => ({value: o[key], label: o[key]}))
          .orderBy(['label'], ['asc'])
          .value()        
      )
    );

    const columns = [
      {
        dataField: 'Project phase/component',
        text: 'Project phase/component',
        sort: true,
        filter: selectFilter({options: optionsByField[fieldsWithSelectFilter[0]]})
      },
      {
        dataField: 'Target sector',
        text: 'Sector',
        sort: true,
        filter: selectFilter({options: optionsByField[fieldsWithSelectFilter[1]]})
      },
      {
        dataField: 'Theme',
        text: 'Theme',
        sort: true,
        filter: selectFilter({options: optionsByField[fieldsWithSelectFilter[2]]})
      },
      {
        dataField: 'Type of resource',
        text: 'Resource',
        sort: true,
        filter: selectFilter({options: optionsByField[fieldsWithSelectFilter[3]]})
      },
      {
        dataField: 'Resource Name',
        text: 'Resource Name',
        sort: true,
        filter: textFilter(),
        formatter: (cell, row ) =>   <Fragment>
          <a href={row['web page']} rel="noreferrer" target="_blank">{row['Resource Name']}</a>
        </Fragment>
      },
      {
        dataField: 'Institution (lead)',
        text: 'Institution (lead)',
        sort: true
      },
      {
        dataField: 'Description',
        text: 'Description',
        sort: true,
        headerStyle: () => ({ width: "30%" })
      },
      {
        dataField: 'Area covered',
        text: 'Area covered',
        sort: true
      },
      {
        dataField: 'Target user',
        text: 'Target user',
        sort: true
      },
    ];

    return <div className="wrapper-tools">
      <h3>Additional resources</h3>
      <p>
        <strong>Moving towards project implementation</strong><br/><br/>
        CRISP is a valuable tool that supports project managers to carry out a climate risk screening.
      </p>
      <p>
      This section presents some examples of additional resources that can be used to plan and/or further strengthen interventions aiming to promote promising relevant agricultural practices, technologies, capacities and/or policies that help increase climate resilience and adaptive capacity of communities, value-chains and Agri-food systems.
      </p>
      <p>
      The selected resources include science-based tools, approaches and methodologies that can be used along the different project implementation phases/components: i) initial climate risk assessment; ii) response prioritization; iii) intervention and activity design or iv) project monitoring, evaluation and learning.
      </p>
      { /* 
      <img src="./images/tools/CRISP_Additional_tools_Fig1.png" alt="CRISP"/>
      <p className="img-footer">
        Figure 1 : Illustration of different project implementation phases and components. Adapted from <a href="https://hdl.handle.net/10568/81374" target="_blank" rel="noreferrer">https://hdl.handle.net/10568/81374</a> (Girvetz et al., 2017)
      </p>
      */}
      <p>
      Please keep in mind that this is not a comprehensive list but rather a teaser aiming to illustrate some possibilities and examples of use under specific contexts.
      </p>
      <p>
      To find useful resources you can filter/search according to:
      </p>
      <ul>
        <li>Project phase/component of your interest</li>
        <li>Relevant target sector of your intervention</li>
        <li>Resource theme</li>
        <li>Resource name</li>
        <li>Resource type</li>
      </ul>
      <p>
        As a result of your selections, you will get a brief description and a link to the relevant resources.
        Please take the time to navigate and discover!
      </p>
      <div className="tools-table">
        <ToolkitProvider
          keyField="factorName"
          data={toolsData}
          columns={columns}
          columnToggle
        >
          {
            props => <Fragment>
              <BootstrapTable
                keyField="Resource Name"
                { ...props.baseProps }
                bordered={ false }
                hover
                striped
                condensed
                filter={ filterFactory() }
                defaultSorted={[{
                  dataField: 'Project phase/component',
                  order: 'asc'
                }]} 
              />  
            </Fragment>
          }
        </ToolkitProvider>      
      </div>      
    </div>
  }
}
export default Background;