import { Component, Fragment } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import * as _ from 'lodash';
import { Context } from '../../context/context';
import {
  getNodesAndLinks,
  getICModelLinksFromNode
} from '../../data/DataUtils';
import { 
  FACTOR_ICONS, 
  FACTOR_ICON_SIZE,
  FACTOR_SPECIALIZATIONS,
  FACTOR_NAME_SPLIT_STRING,
  FACTOR_TYPES_SORTED
} from '../../Constants';
import { enhanceFactorSubtype, enhanceLinkType, pluralize } from "../../utils/label-utils";
import { Button, Offcanvas, OverlayTrigger, Popover } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import { addFactor } from "../../utils/url-utils";

class ICTable extends Component {

  static contextType = Context;

  state = {
    onlySelectedFactors: false
  }
  render() {
    let {
      ICModelLinks,
      selectedFactors,
      selectFactor,
      factorsInContext,
      rejectedFactorTypes, 
      rejectedFactorSubTypes,
      onlySelectedFactorsVisible,
      fetchRelatedFactorsInContext,
      relatedFactorsInContext,
      resetRelatedFactorsInContext,
      isFactorSelected,
      resetSelectedFactors
    } = this.context;
    
    // get only the nodes (factors) from the network
    // here we ommit the list of rejected factors, it´s 
    // a filter that only applies in the network
    let data = getNodesAndLinks(
        ICModelLinks,
        factorsInContext, 
        rejectedFactorTypes, 
        rejectedFactorSubTypes,
        onlySelectedFactorsVisible ? selectedFactors : []
      ),
      nodes = data.nodes,
      links = data.links,
    // group nodes by factor type, we 
    // want a map object with all factor
    // types, so start with an empty map
    // placeholder, and merge it with our data
    factorsByType = _.merge(
      _.zipObject(
        FACTOR_SPECIALIZATIONS,
        FACTOR_SPECIALIZATIONS.map(f => [])
      ),
      _(nodes)
        .map('data')
        // enrich factor with context data
        .map(o => _.merge(o, factorsInContext[o.factorName]))
        .groupBy('factorTypeName')
        .value()
    ),
    // get factor types, sorted
      tabLabels = FACTOR_TYPES_SORTED,
    // handler for row selection
      onRowSelect = (row, isSelect, rowIndex, e) => {
        
        if(e.target.type !== 'checkbox') {
          // we are clicking on a resource link
          return false;
        }

        let node = _.find(nodes, ['label', row.factorName]);
        if(!_.isNil(node)) {
          addFactor(node.data.factorShortId);
          selectFactor(node);
        }          
      }

    // column and interaction definitions for the table
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: onRowSelect
    };
    const columns = [
      {
        dataField: 'factorName',
        text: 'Factor',
        sort: true
      }, 
      {
        dataField: 'factorSubtypeName',
        text: 'Type',
        sort: true,
        formatter: (cell, row) => enhanceFactorSubtype(row.factorTypeName, row.factorSubtypeName)
      }, 
      {
        dataField: 'contextualDescription',
        text: 'Description',
        formatter: (cell, factorInContext ) =>   <p>{ factorInContext?.contextualDescription}</p>,
        headerStyle: () => ({ width: "30%" })
      },
      {
        dataField: 'links',
        isDummyField: true,
        text: 'Links',
        headerStyle: () => ({ width: "30%" }),
        formatter: (cell, row, rowIndex) => <Fragment><ul key={rowIndex}>
            {
              _(links)
                // get all links where our factor is present
                .filter(d => [d.source, d.target].includes(row.factorName))
                // a link is { source , target, type} where type is a 2-length array containing
                // data about the 2 directions of the link.
                .map(obj => _.head(_.reject(obj.type, ['factorName', row.factorName])))
                .value()
                .map((link, i) => {
                  return <li key={rowIndex + '-' + i}>
                    {_.capitalize(enhanceLinkType(link.linkType))} "{link.factorName}" <span className="link-related-factor">({link.factorTypeName})</span>
                  </li>
                  }
                )
            }
            </ul>
            <Button variant="link" size="sm" onClick={() => {
              // get links containing this node
              let links = getICModelLinksFromNode(row.factorName, ICModelLinks);
              // get all involved nodes from the links of the hovered node. This includes the
              // hovered node (first in the array) itself and its neighbourghs.
              fetchRelatedFactorsInContext(
                _.chain(links)
                  .values()
                  .flatten()
                  .unionBy('factorName')
                  .partition(o => _.isEqual(o.factorName, row.factorName))
                  .flatten()
                  .value()
              );
            }}>
              + info on factor links
            </Button>
          </Fragment>
      },
      {
        dataField: 'tags',
        text: 'Tags',
        sort: true,
        formatter: (cell, factorInContext) => factorInContext?.tags.split(';').join(', ')
      },
      {
        dataField: 'resources',
        text: 'Resources',
        formatter: (cell, factorInContext ) =>   <Fragment>
          {
            factorInContext?.resources && 
            factorInContext?.resources
              .split(';')
              .map((resource, index) => {
                let citationUrl = resource.split(FACTOR_NAME_SPLIT_STRING);
                return _.isEmpty(citationUrl[1]) ?
                  <div key={index}> {citationUrl[0]} </div>
                  :
                  <div key={index}><a href={citationUrl[1]} rel="noreferrer" target="_blank">{citationUrl[0]}</a></div>;
              })
          }
        </Fragment>
      }
    ];

    const { SearchBar, ClearSearchButton } = Search;

    // customize generic selectRow object for each table (for each factor type)
    // factorsByType is { Adaptation: Array(18), Vulnerability: Array(23), … }
    // so add a selected property on each array
    factorsByType = _.mapValues(
      factorsByType,
      value => _.set(
        value,
        'selected',
        _.intersection(
          _.map(value, 'factorName'),
          _.map(selectedFactors, 'label')
        )
      )        
    );

    return <div className="table-view">
      <OverlayTrigger
        trigger="click"
        key={"right"}
        placement={"right"}
        overlay={
          <Popover id={`popover-positioned`}>
            <Popover.Body>                                    
                <p>
                  Re-check in the table for each impact chain component (proceed tab by tab) the factors that  you have selected as relevant for your area under review. Select further factors if necessary.
                  <br/> 
                  Once you are happy with your selection do generate a results report by clicking on "Export data" at the top right of this page.                                 
                </p>
            </Popover.Body>
          </Popover>
        }
      >
        <InfoCircleFill size={24} color='rebeccapurple'/> 
      </OverlayTrigger>
      { 
        !_.isEmpty(relatedFactorsInContext) && 
          <Offcanvas show={!_.isEmpty(relatedFactorsInContext)} 
            onHide={() => resetRelatedFactorsInContext()}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                Related factors to:<br/>
                <h4>{_.first(relatedFactorsInContext).factorName}</h4>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {
                _.chain(relatedFactorsInContext)
                  .drop()
                  .groupBy('factorTypeName')
                  .values()
                  .value().map( (factors, i) => {
                    let factorTypeName = _.sample(factors).factorTypeName
                    return <div key={i} className='factor-type-block'>
                      <h6>
                        <svg
                          width={FACTOR_ICON_SIZE}
                          height={FACTOR_ICON_SIZE}>
                            <use href={"#"+factorTypeName}></use>
                        </svg>
                        {factorTypeName} ({factors.length})
                      </h6>
                      { factors.map((factor, j) => <div key={j} className={'factor related-factor-'+ _.snakeCase(factor.factorName)}>
                          <div className='factor-name'>
                            {factor.factorName}
                            { isFactorSelected(factor) && <svg color='green' viewBox='0 0 16 16' width={16} height={16}>
                                <symbol id='FactorSelected' viewBox='0 0 16 16' width={16} height={16}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                  </svg>
                                </symbol>
                                <use xlinkHref='#FactorSelected'></use>
                              </svg>                              
                            }
                            <br/>
                            <p>
                            { _.startCase(
                                _.replace(factor.factorSubtypeName, factor.factorTypeName)
                              )
                            },{' '} 
                              {[
                                enhanceLinkType(factor.linkType),
                                _.lowerCase(_.first(relatedFactorsInContext).factorName)
                              ].join(' ')}.
                            </p>
                          </div>
                          <div className='factor-description'>
                            {factor.contextualDescription}                          
                          </div>
                          <div className='factor-resources'>
                            {
                              factor.resources
                              .split(';')
                              .map(resource => {
                                let citationUrl = resource.split(FACTOR_NAME_SPLIT_STRING);
                                return _.isEmpty(citationUrl[1]) ?
                                  <div> {citationUrl[0]} </div>
                                  :
                                  <div><a href={citationUrl[1]} rel="noreferrer" target="_blank">{citationUrl[0]}</a></div>;
                              })
                            }                          
                          </div>
                        </div>)
                      }
                    </div>
                  })
              }
            </Offcanvas.Body>
          </Offcanvas>
      }

      { /* Table */ }
      <Tabs
        defaultActiveKey={_.first(tabLabels)}
        id="tab-factors"
        className="tab-factors"
        fill>
        {
          tabLabels.map(
            (factorType, key) => <Tab
              key={key} 
              eventKey={factorType} 
              title={<div className="tab">
                <div className="factor-type">
                  <svg width={FACTOR_ICON_SIZE*1.5} height={FACTOR_ICON_SIZE}>
                    <image xlinkHref={FACTOR_ICONS[factorType]}
                      width={FACTOR_ICON_SIZE}          
                      height={FACTOR_ICON_SIZE}/>
                  </svg>
                  {pluralize(factorType)}
                </div>
                <br/>
                  <span className="badge">
                    { factorsByType[factorType].length === 0 ? 'Not shown' : `Selected ${factorsByType[factorType].selected.length} out of ${factorsByType[factorType].length}`
                    }                    
                  </span>
                </div>}
              >
                <ToolkitProvider
                  keyField="factorName"
                  data={ this.state.onlySelectedFactors ?                     
                    _.filter(
                      factorsByType[factorType],
                      factor => factorsByType[factorType].selected.includes(factor.factorName)
                    ) : factorsByType[factorType]
                }
                  columns={ columns }
                  search
                >
                  {
                    props => (
                      <Fragment>
                        <div className="search-wrapper">
                          <div className="search-wrapper-controls">
                            <SearchBar { ...props.searchProps } srText = "" placeholder="Type text to search..."/>
                            <ClearSearchButton { ...props.searchProps } />
                          </div>                          
                          <div>
                            <input type="checkbox" id="onlySelectedFactors" onClick={() => {
                              this.setState({
                                onlySelectedFactors: !this.state.onlySelectedFactors
                              })
                            }}/><label htmlFor="onlySelectedFactors">List only selected factors</label>
                          </div>
                          <div>
                            <Button size="sm" variant="link" onClick={() =>resetSelectedFactors()}>
                              Clear selection
                            </Button>
                          </div>                          
                        </div>                        
                        <BootstrapTable
                          { ...props.baseProps }                          
                          selectRow={ _.set(
                              _.clone(selectRow),
                              'selected',
                              factorsByType[factorType].selected
                            )}
                          bordered={ false }
                          hover
                          striped
                          condensed
                        />  
                      </Fragment>
                    )
                  }                  
                </ToolkitProvider>                              
              </Tab>
          )
        }      
      </Tabs>
    </div>
  }
}

export default ICTable