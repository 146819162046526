import * as _ from 'lodash';
import {
  FACTOR_ID_SPLIT_STRING,
  URL_ID_IMPACT_CHAIN,
  URL_ID_FACTORS,
  URL_ID_REGEX,
  URL_ID_FACTOR_TYPES,
  URL_ID_FACTOR_SUBTYPES,
  URL_CRISP_SESSION
} from '../Constants';
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string'

let setParamsEncoded = function(urlParams) {
  let encodedParams = compressToEncodedURIComponent(urlParams.toString());
  let urlParamsEncoded = new URLSearchParams(`${URL_CRISP_SESSION}=${encodedParams}`);

  window.history.pushState({}, '', `${window.location.pathname}#${urlParamsEncoded}`);
}

let getParamsDecoded = function() {
  const urlParams = new URLSearchParams(window.location.hash.replace("#", ""));
  const urlParamsDecodedStr = decompressFromEncodedURIComponent(urlParams.get(URL_CRISP_SESSION));
  return new URLSearchParams(urlParamsDecodedStr);
}

// set impact chain identifier as an URL param
export function addImpactChain(id) {
  const urlParams = getParamsDecoded();
  urlParams.set(URL_ID_IMPACT_CHAIN, id);
  setParamsEncoded(urlParams);
};

export function addFactor(id) {      
    const urlParams = getParamsDecoded();
    const fids = urlParams.get(URL_ID_FACTORS);
    
    if(_.isNull(fids)) {
      urlParams.set(URL_ID_FACTORS, id);
    }
    else {
      let ids = fids.split(FACTOR_ID_SPLIT_STRING);
      let i = _.indexOf(ids, id);
      if(i !== -1)
        _.pullAt(ids, i);
      else
        ids = [...ids, id];

      urlParams.set(
        URL_ID_FACTORS,
        ids.join(FACTOR_ID_SPLIT_STRING)
      );
    }
    setParamsEncoded(urlParams);
};

export function addRejectedFactorTypeIDs(names, rejectedFactorSubTypes = undefined, FACTOR_TYPE_IDS) {
  const urlParams = getParamsDecoded();

  if(_.isEmpty(names))
    urlParams.delete(URL_ID_FACTOR_TYPES);  
  else {
    // change factor type names by its ids
    let ids = names.map(name => _.find(FACTOR_TYPE_IDS, ['name', name])?.id);
    urlParams.set(URL_ID_FACTOR_TYPES, ids.join(FACTOR_ID_SPLIT_STRING));
  }    
  setParamsEncoded(urlParams);
  
  if(!_.isUndefined(rejectedFactorSubTypes))
    addRejectedFactorSubTypeIDs(rejectedFactorSubTypes, FACTOR_TYPE_IDS);
}

export function addRejectedFactorSubTypeIDs(names, FACTOR_TYPE_IDS) {
  const urlParams = getParamsDecoded();

  if(_.isEmpty(names))
    urlParams.delete(URL_ID_FACTOR_SUBTYPES);  
  else {
    // change factor subtype names by its ids
    let ids = names.map(name => _.find(FACTOR_TYPE_IDS, ['name', name])?.id);
    urlParams.set(URL_ID_FACTOR_SUBTYPES, ids.join(FACTOR_ID_SPLIT_STRING));
  }
  setParamsEncoded(urlParams);
}

export function deleteAllURLParams() {
  const urlParams = new URLSearchParams(window.location.search);
  for (const [key, value] of urlParams.entries()) {
    urlParams.delete(key)
  }
  window.history.pushState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
}

export function getImpactChainID () {
  const urlParams = getParamsDecoded();

  const ICID = urlParams.get(URL_ID_IMPACT_CHAIN);
  
  return !_.isNull(ICID) && URL_ID_REGEX.test(ICID) ? ICID : null;
}

export function getFactorIDs() {
  const urlParams = getParamsDecoded();
  const FIDS = urlParams.get(URL_ID_FACTORS);

  if(!_.isNull(FIDS)) {
    let ids = FIDS
      .split(FACTOR_ID_SPLIT_STRING)
      .filter(id => URL_ID_REGEX.test(id));
    
    return _.isEmpty(ids) ? null : ids;
  }

  return null;
}

export function getRejectedFactorTypeIDs(FACTOR_TYPE_IDS) {
  const urlParams = getParamsDecoded();
  const FTIDS = urlParams.get(URL_ID_FACTOR_TYPES);

  if(!_.isNull(FTIDS)) {
    let ids = FTIDS.split(FACTOR_ID_SPLIT_STRING);
    // change factor type ids by its names
    let names = ids.map(id => _.find(FACTOR_TYPE_IDS, ['id', id])?.name);
    return _.isEmpty(names) ? null : names;
  }

  return null;
}

export function getRejectedFactorSubTypeIDs(FACTOR_TYPE_IDS) {
  const urlParams = getParamsDecoded();
  const FSTIDS = urlParams.get(URL_ID_FACTOR_SUBTYPES);
  if(!_.isNull(FSTIDS)) {
    let ids = FSTIDS.split(FACTOR_ID_SPLIT_STRING);
    // change factor subtype ids by its names
    let names = ids.map(id => _.find(FACTOR_TYPE_IDS, ['id', id])?.name);
    return _.isEmpty(names) ? null : names;
  }

  return null;
}