
import React, { Component } from "react";

class Videos extends Component {

  constructor(props) {
    super(props);
    this.usecasesRef = React.createRef();
    this.testimonialsRef = React.createRef();
  }

  render() {
    return <div className="wrapper-tools">
      <h3>Tutorial videos</h3>
      <p>
        Find below some videos showcasing various practical use cases / testimonials of the CRISP tool.<br/>These videos are aimed to explore the tool's capabilities and potential in action.
      </p>
      <ul>
        <li onClick={ () => { window.scrollTo(0, this.usecasesRef.current.offsetTop); } }>Use cases</li>
        <li onClick={ () => { window.scrollTo(0, this.testimonialsRef.current.offsetTop); } }>Testimonials</li>
      </ul>
      <br/>
      <h1 ref={this.usecasesRef}>{''}</h1>
      <h3 style={{border:"none", textDecoration: "underline"}}>Use cases</h3>
      <h5>CRISP tool – short demo</h5>     
      <p className="video-description">
        This short demo video introduces the CRISP tool to conduct a climate risk screening to articulate science-based adaptation hypotheses based on the development of impact chains.
      </p>
      <video controls width="75%">
        <source src="./video/CRISP_short_demo_2mins47.mp4" type="video/mp4" />  
      </video>

      <h5>CRISP tool – demo – starting from the hazard</h5> 
      <p className="video-description">
        In this video we showcase how to use the CRISP tool to conduct a climate risk screening to articulate science-based adaptation hypotheses based on the development of impact chains. In this case study we are interested in answering the question: “Which are the main climate-related hazards, impacts, vulnerabilities and potential adaptation options relevant to the farming system in which my agricultural development project will be implemented?"
      </p>    
      <video controls width="75%">
        <source src="./video/CRISP_demo_starting_from_the_hazard_3m43.mp4" type="video/mp4" />  
      </video>

      <h5>CRISP tool – demo – starting from the adaptation</h5>
      <p className="video-description">
        In this video we showcase how to use the CRISP tool to conduct a climate risk screening to articulate science-based adaptation hypotheses based on the development of impact chains. In this case study we are interested in answering the question: "Are the adaptation options promoted by my project responding to all the main climatic hazards and impacts affecting the specific farming system where they are implemented? Are there additional suitable adaptation options my project is not yet implementing and that would be worth considering?"
      </p>
      <video controls width="75%">
        <source src="./video/CRISP_demo_starting_from_adaptation_4m12.mp4" type="video/mp4" />  
      </video>

      <h5>How to use CRISP during the project/proposal planning phase</h5>
      <p className="video-description" style={{marginBottom: '.5rem'}}>
        This video, recorded during a <a href="https://alliancebioversityciat.org/events/webinar-knowledge-sharing-practical-guidance-use-crisp-tool" target="_blank">Knowledge Sharing webinar</a>, illustrates how CRISP can be applied to develop the Climate rationale and backstop your project design with scientific evidence.
      </p>
      <p className="video-description" style={{marginBottom: '.5rem'}}>
        Before watching the video consider the following potential use case: "Your research or rural development organization has been reached by the national government of Mali to develop a joint proposal, aligned with their NDC, and aiming to increase smallholder farmers resilience to climate change, through the promotion of agricultural adaptation solutions. They want to focus the project in the Kayes, Koulikoro, and Ségou regions. The climate related challenges to be tackled include intense droughts, decrease in rainfall from 1000 mm/year to less than 500 mm/year."
      </p>
      <p className="video-description" style={{marginBottom: '.5rem'}}>
        This video will show you how you can:
      </p>
      <p className="video-description" style={{marginBottom: '.5rem'}}>
        <ol>
          <li>
            <b>Develop the Climate rationale to backstop your project design with scientific evidence</b>
            <ol>
              <li>Get access to the available knowledge on Climate Risk Impacts affecting the agricultural farming system that characterizes your target geography</li>
              <li>Develop a tailored Climate Impact Chain SPECIFIC for your project</li>
            </ol>
          </li>
          <li><b>Validate/identify the agricultural technologies (adapatation options)</b> to be promoted to build adaptive capacity and climate resilience</li>
          <li><b>Get insights on relevant interventions/partnerships</b> that could strengthen your project design</li>
        </ol>
      </p>
      <video controls width="75%">
        <source src="./video/CRISP_how-to-planning-phase.mp4" type="video/mp4" />  
      </video>

      <h5>How to use CRISP in the project implementation phase</h5>
      <p className="video-description" style={{marginBottom: '.5rem'}}>
        This video, recorded during a <a href="https://alliancebioversityciat.org/events/webinar-knowledge-sharing-practical-guidance-use-crisp-tool" target="_blank">Knowledge Sharing webinar</a>, illustrates a potential use case about an ongoing project in Makindu ward, Makueni county, Kenya. This project is promoting some climate smart agriculture approaches but project implementers are looking for other potential climate-resilient interventions.
        This video will allow you to explore if:
      </p>
      <p className="video-description" style={{marginBottom: '.5rem'}}>
        <ol>
          <li>The adaptation options promoted by the project are responding to all the main climatic hazards and impacts affecting the specific farming system where they are implemented</li>
          <li>There are additional suitable adaptation options the project is not yet implementing and that would be worth considering</li>
        </ol>
      </p>
      <video controls width="75%">
        <source src="./video/CRISP_how-to-implementation-phase.mp4" type="video/mp4" />
      </video>
      <br/>
      <h1 ref={this.testimonialsRef}>{''}</h1>
      <br/>
      <h3 style={{border:"none", textDecoration: "underline"}}>Testimonials</h3>
      <h5>CRISP tool in use - Bolivia</h5>     
      <p className="video-description">
        CRISP tool in Use: A practical Example - Bolivia. A testimonio by Carlos Saavedra, GIZ Senior Adviser, Climate Resilience.
      </p>
      <video controls width="75%">
        <source src="./video/testimonial-bolivia.mp4" type="video/mp4" />  
      </video>

      <h5>CRISP tool in use - Zambia</h5>     
      <p className="video-description">
        CRISP tool in Use: A practical Example - Zambia. A testimonio by Able Bwalya, GIZ Technical advisor E-PICSA and EcoPM Projects.
      </p>
      <video controls width="75%">
        <source src="./video/testimonial-zambia.mp4" type="video/mp4" />  
      </video>
      <br/>
      <br/>
    </div>
  }
}
export default Videos;